<template>
  <div class="radio-button_wrapper" :class="orientation">
    <label
      v-for="(option, i) of radioOptions"
      :key="i"
      class="radio-button_item"
      :class="labelPosition"
    >
      <span class="radio-button_span" :for="option">{{ option }}</span>
      <input
        class="radio-button_input"
        :id="option"
        v-model="checked"
        type="radio"
        :value="radioValue || option"
        @change="onchange"
        :name="radioName"
      />

      <span class="radio-button_checkmark" />
    </label>
  </div>
</template>

<script>
export default {
  emits: ["checkedRadio"],
  props: {
    radioOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    orientation: {
      type: String,
      required: false,
      default: "horizontal",
    },
    labelPosition: {
      type: String,
      required: false,
    },
    radioName: {
      type: String,
      required: true,
    },
    radioValue: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    onchange() {
      this.$emit("checkedRadio", this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/RadioButton/radioButton.scss";
</style>