<template>
  <Transition duration="550" name="nested">
    <div v-if="show" class="modal_backdrop outer" @click.self="$emit('clickbackgdrop')">
      <div class="modal_inner-wrapper inner" :class="modalPaddingNone ? 'modal_inner-wrapper--padding-none' : ''">
        <div v-if="closeButton" class="modal_close-button" @click="$emit('close')">
          <img :src="closeIcon" alt="img" />
        </div>
        <slot></slot>
      </div>
    </div>
  </Transition>
</template>

<script>
import InputField from "@/components/atoms/InputField/InputField.vue";
import Button from "@/components/atoms/Button/Button.vue";
import Link from "@/components/atoms/Link/Link.vue";
import CloseIcon from "@/assets/icons/icon-x.svg";

export default {
  emits: ["clickbackgdrop", "close"],
  components: {
    InputField,
    Button,
    Link,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    modalPaddingNone: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      closeIcon: CloseIcon,
    };
  },
};
</script>

<style lang="scss">
@import "@/components/containers/Modal/modal.scss";
</style>