<template>
  <label class="horizontal-card_wrapper horizontal-card_wrapper--radio-button" :class="price=='NoApiCall' ? 'horizontal-card--no-price' : ''" :for="cardOptions">
    <RadioButton
      v-if="radioButton"
      class="horizontal-card_radio-button"
      :radioOptions="[cardOptions]"
      labelPosition="radio-button--label-hidden"
      :radioName="checkerName"
      @checkedRadio="checkedCard"
    />
    <div class="horizontal-card_image-wrapper">
      <Image
        class="horizontal-card_image image--horizontal-card"
        :src="cardImage != '' ? cardImage : defaultImage"
        :alt="cardImageAlt"
      />
    </div>
    <div class="horizontal-card_product-info-wrapper">
      <p class="horizontal-card_title">
        {{ title }}
      </p>
      <p class="horizontal-card_subtitle">{{ subtitle }}</p>
      <div class="horizontal-card_product-details-article-wrapper">
        <span class="horizontal-card_item-details">TU Artikel nr:</span
        ><span class="horizontal-card_item-details--bold">{{
          articleNumber
        }}</span>
      </div>
      <div class="horizontal-card_product-details-article-wrapper">
        <span class="horizontal-card_item-details">Leverancier Code:</span
        ><span class="horizontal-card_item-details--bold">{{
          suppliercode
        }}</span>
      </div>
      <div class="horizontal-card_product-details-article-wrapper">
        <span class="horizontal-card_item-details" v-if="cableLength">Lengte kabel:</span
        ><span class="horizontal-card_item-details--bold">{{
          cableLength
        }}</span>
      </div>
    </div>
    <div class="horizontal-card_price-info-wrapper" v-if="price!='NoApiCall'">
      <div class="horizontal-card_price-wrapper">
        <p class="horizontal-card_price">€ {{ brutoPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR"
            })
          }} Bruto</p>
        <p class="horizontal-card_price"><b>€ {{ price.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR"
            })
          }} Netto</b></p>
        <div v-if="cardTag" class="horizontal-card_tag">{{ cardTag }}</div>
      </div>
      <div class="horizontal-card_availability-wrapper">
        <div>
          <div class="horizontal-card_availability-inner-wrapper">
            <Image
              class="horizontal-card_check-icon image--vertical-card-check-icon"
              :src="quantityIcon"
              alt="icon"
            />
            <span :class="quantityClass">Op voorraad: {{ loadQuantity() }}</span>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import RadioButton from "@/components/atoms/RadioButton/RadioButton.vue";

import DefaultImage from "@/assets/images/default-image.png";
import StepsSprite from "@/assets/icons/steps-sprite.svg";
import WarningIcon from "@/assets/icons/warning.svg";

export default {
  emits: ["selectedCableCard"],
  components: {
    Image,
    RadioButton,
  },
  props: {
    cardOptions: {
      type: String,
      required: false,
    },
    radioButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    checkerName: {
      type: String,
      required: false,
    },
    cardImage: {
      type: String,
      required: false,
      default: DefaultImage,
    },
    cardImageAlt: {
      type: String,
      required: false,
      default: "card image",
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    articleNumber: {
      type: String,
      required: false,
    },
    suppliercode: {
      type: String,
      required: false,
    },
    productQuantity: {
      type: String,
      required: false,
    },
    deliveryTimeDelay: {
      type: String,
      required: false,
      default: "0",
    },
    cardTag: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: true,
    },
    brutoPrice: {
      type: Number,
      required: false,
    },
    cableLength: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    quantityIcon: StepsSprite + "#step-5-green",
    quantityClass: "horizontal-card_availability--green",
    deliveryTime: "Morgen bezorgd",
    defaultImage: DefaultImage
  }),
  methods: {
    checkedCard(checked) {
      this.$emit(
        "selectedCableCard",
        checked,
        this.cardOptions,
        this.checkerName,
        this.cardImage,
        this.cardImageAlt,
        this.title,
        this.subtitle,
        this.articleNumber,
        this.suppliercode,
        this.productQuantity,
        this.deliveryTimeDelay,
        this.price,
        this.cableLength,
        this.brutoPrice
      );
    },
    loadQuantity() {
      if (this.productQuantity > 0) {
        // show that product is available
        this.quantityIcon = StepsSprite + "#step-5-green";
        this.quantityClass = "horizontal-card_availability--green";
        this.deliveryTime = "Morgen bezorgd";
        return this.productQuantity;
      } else if (this.productQuantity <= 0) {
        // show text that product is not available and show delivery time in weeks
        this.quantityIcon = WarningIcon;
        this.quantityClass = "horizontal-card_availability--yellow";
        this.deliveryTime = this.deliveryTimeDelay;
        return "Niet op voorraad";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/HorizontalCard/horizontalCard.scss";
</style>
