<template>
  <a class="link" :href="url" @click="clickedLink">{{ text }}</a>
</template>

<script>
export default {
  emits: ["clickedlink"],
  name: "Link",
  props: {
    text: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickedLink() {
      this.$emit("clickedlink");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/Link/link.scss";
</style>
