
//AUTH
export const LOGIN_ACTION = '[actions] login user';
export const LOGOUT_ACTION = '[actions] logout user';
export const AUTO_LOGOUT_ACTION = '[actions] auto logout';
export const AUTH_ACTION = '[actions] DO User Auth';
export const AUTO_LOGIN_ACTION = '[actions] Auth Auto Login';

export const GET_USER_TOKEN_GETTER = '[getters] auth user token';
export const IS_USER_AUTHENTICATED_GETTER = '[getters] is user authenticated';
export const GET_USERNAME = '[getters] get username';
export const GET_CUSTOMERNUMBER = '[getters] get customernumber'
export const GET_AUTOLOGOUT = '[getters] get autologout';

export const SET_AUTO_LOGOUT_MUTATION = '[mutations] set auto logout'
export const SET_USER_TOKEN_DATA_MUTATION = '[mutations] set user token data';
export const LOADING_SPINNER_SHOW_MUTATION = '[mutations] show loading spinner';
//APP
export const CHARGING_TIME = '[actions] charging time'
export const VEHICLE_BRANDS_LIST = '[actions] vehicle brands list'
export const VEHICLE_MODEL_LIST = '[actions] vehicle models list'
export const PRODUCT_CARD_LIST = '[actions] product car list'
export const PRODUCT_ACCESSORIES = '[actions] product accessories list'
export const ADD_TO_CART = '[actions] add product to cart'
export const GET_CHARGING_TIME = '[getters] get charging time'
export const GET_VEHICLE_BRANDS_LIST = '[getters] get vehicle brands list'
export const GET_VEHICLE_MODEL_LIST = '[getters] get vehicle models list'
export const GET_VEHICLE_MODEL = '[getters] get model'
export const GET_VEHICLE_BRAND = '[getters] get brand'
export const GET_VEHICLE_VERSION = '[getters] get version'
export const GET_VEHICLE_CHARGING_UNITS = '[getters] get charging units number'
export const GET_COSTS = '[getters] get costs'
export const GET_CHARGER_POSITION = '[getters] get charger position'
export const GET_CHARGING_CABLE = '[getters] get charging cable'
export const GET_PAHSE = '[getters] get selected phase'
export const GET_AMPERES = '[getters] get selected amperes'
export const GET_LOADBALANCING = '[getters] get selected loadbalancing'
export const GET_PRODUCT_CARD_LIST = '[getters] get product car list'
export const GET_CABLES_LIST = '[getters] get cables list'
export const GET_ADDITIONAL_ACCESSORIES_LIST = '[getters] get additional accessories list'
export const GET_SELECTED_PRODUCT = '[getters] get selected product'
export const GET_SELECTED_ACCESSORIES = '[getters] get selected accessories'
export const GET_SELECTED_CABEL = '[getters] get selected cabel'
export const GET_CABLE_TYPE = '[getters] get cable type'

export const SET_CHARGING_TIME = '[mutations] set charging time'
export const SET_FIRST_STEP_CHOICES = '[mutations] set first step choices'
export const SET_SECOND_STEP_CHOICES = '[mutations] set second step choices'
export const SET_THIRD_STEP_CHOICES = '[mutations] set third step choices'
export const SET_VEHICLE_BRANDS_LIST = '[mutations] set vehicle brands list'
export const SET_VEHICLE_MODEL_LIST = '[mutations] set vehicle models list'
export const SET_MODEL_BRAND_VERSION = '[mutations] set model brand version'
export const SET_PRODUCT_CARD_LIST = '[mutations] set product car list'
export const SET_PRODUCT_ACCESSORIES = '[mutations] set product accessories list'
export const SET_FOURTH_STEP_CHOICES = '[mutations] set fourth step choices'
export const SORT_PRODUCTS = '[mutations] product cards sorting'
