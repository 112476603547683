<template>
  <div class="footer-dynamic_wrapper">
    <div class="footer-dynamic_inner-wrapper">
      <div class="footer-dynamic_col">
        <h3>De voordelen van Technische Unie</h3>
        <div classes="footer-dynamic_list">
          <div class="footer-dynamic_list-item">
            <img :src="arrow" alt="arrow" class="footer-dynamic_arrow-icon" />
            Keuzegemak
          </div>
          <div class="footer-dynamic_list-item">
            <img :src="arrow" alt="arrow" class="footer-dynamic_arrow-icon" />
            Bestelgemak
          </div>
          <div class="footer-dynamic_list-item">
            <img :src="arrow" alt="arrow" class="footer-dynamic_arrow-icon" />
            Administratiegemak
          </div>
          <div class="footer-dynamic_list-item">
            <img :src="arrow" alt="arrow" class="footer-dynamic_arrow-icon" />
            Leveringsgemak
          </div>
        </div>
      </div>
      <div class="footer-dynamic_col footer-dynamic_col--second">
        <div class="footer-dynamic_item-wrapper">
          <a href="https://www.c.technischeunie.nl/klantenservice.html">
            <div class="footer-dynamic_icon-wrapper">
              <img
                :src="questionmark"
                alt="questionmark icon"
                class="footer-dynamic_icon"
              />
            </div>
          </a>
          <div class="footer-dynamic_item-text-wrapper">
            <a href="https://www.c.technischeunie.nl/klantenservice.html" class="footer-dynamic_item-text">Klantenservice</a>
            <a href="https://www.c.technischeunie.nl/klantenservice.html" class="footer-dynamic_item-text">
              Antwoorden op veelgestelde vragen</a>
          </div>
        </div>

        <div class="footer-dynamic_item-wrapper">
          <a href="https://www.technischeunie.nl/vestigingen/">
          <div class="footer-dynamic_icon-wrapper">
            <img
              :src="location"
              alt="location icon"
              class="footer-dynamic_icon"
            />
          </div>
          </a>
          <div class="footer-dynamic_item-text-wrapper">
            <a href="https://www.technischeunie.nl/vestigingen/" class="footer-dynamic_item-text">Technische Unie bezoeken?</a>
            <a href="https://www.technischeunie.nl/vestigingen/" class="footer-dynamic_item-text">Alle vestigingen</a>
          </div>
        </div>
      </div>
      <div class="footer-dynamic_col footer-dynamic_col--third">
        <a href="https://www.technischeunie.nl/vestigingen/">
          <img :src="map" alt="map" class="footer-dynamic_map-image" />
        </a>
      </div>
      <div class="footer-dynamic_medium-col footer-dynamic_medium-col--first">
        <h3 class="footer-dynamic_medium-title title--green">
          Kunnen we helpen?
        </h3>
      </div>
      <div class="footer-dynamic_medium-col">
        <div class="footer-dynamic_item-wrapper">
          <div class="footer-dynamic_icon-wrapper">
            <img
              :src="whatsapp"
              alt="whatsapp icon"
              class="footer-dynamic_icon"
            />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">Stel je vraag via whatsapp</p>
          </div>
        </div>
        <div class="footer-dynamic_item-wrapper">
          <div class="footer-dynamic_icon-wrapper">
            <img :src="phone" alt="phone icon" class="footer-dynamic_icon" />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">
              Bel je verkoopkantoor voor antwoord op vragen en advies
            </p>
            <p class="footer-dynamic_item-text">030 - 1234567</p>
          </div>
        </div>
        <div class="footer-dynamic_item-wrapper">
          <div class="footer-dynamic_icon-wrapper">
            <img :src="mail" alt="mail icon" class="footer-dynamic_icon" />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">Stuur ons een mail</p>
          </div>
        </div>
        <div class="footer-dynamic_item-wrapper">
          <div class="footer-dynamic_icon-wrapper">
            <img
              :src="questionmark"
              alt="questionmark icon"
              class="footer-dynamic_icon"
            />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">
              Klantenservice Antwoorden op veelgestelde vragen
            </p>
          </div>
        </div>
      </div>
      <div class="footer-dynamic_medium-col">
        <div
          class="footer-dynamic_item-wrapper footer-dynamic_item-wrapper--info"
        >
          <div class="footer-dynamic_icon-wrapper--white">
            <img :src="clock" alt="clock icon" class="footer-dynamic_icon" />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">
              Wij zijn telefonisch bereikbaar:
            </p>
            <p class="footer-dynamic_item-text">Maandag t/m vrijdag</p>
            <p class="footer-dynamic_item-text">08.00-19.00 uur</p>
            <br />
            <p class="footer-dynamic_item-text">Bij ons binnenlopen:</p>
            <p class="footer-dynamic_item-text">Maandag t/m vrijdag</p>
            <p class="footer-dynamic_item-text">07.00-17.00 uur</p>
            <p class="footer-dynamic_item-text">Zaterdag 08.00-13.00 uur</p>
          </div>
        </div>
        <div
          class="footer-dynamic_item-wrapper footer-dynamic_item-wrapper--info"
        >
          <div class="footer-dynamic_icon-wrapper--white">
            <img
              :src="locationfilled"
              alt="location icon"
              class="footer-dynamic_icon"
            />
          </div>
          <div class="footer-dynamic_item-text-wrapper">
            <p class="footer-dynamic_item-text">Verkoopkantoor Nieuwegein</p>
            <p class="footer-dynamic_item-text">Blabla straat weg 23</p>
            <p class="footer-dynamic_item-text">1234 XX Nieuwegein</p>
          </div>
        </div>
      </div>
      <div class="footer-dynamic_back-to-top-wrapper">
        <BackToTop />
      </div>
    </div>
  </div>
</template>

<script>
import UnorderedList from "@/components/atoms/UnorderedList/UnorderedList.vue";
import BackToTop from "@/components/atoms/BackToTop/BackToTop.vue";

import Map from "@/assets/images/map.png";
import QuestionmarkIcon from "@/assets/icons/questionmark.svg";
import LocationIcon from "@/assets/icons/location.svg";
import Arrow from "@/assets/icons/li-arrow.svg";
import SocialMediaIcon from "@/assets/icons/social-media-sprite.svg";
import Phone from "@/assets/icons/phone.svg";
import Mail from "@/assets/icons/mail.svg";
import LocationFilled from "@/assets/icons/location-filled.svg";
import ClockIcon from "@/assets/icons/clock-icon.svg";

export default {
  components: {
    UnorderedList,
    BackToTop,
  },
  data: () => ({
    map: Map,
    arrow: Arrow,
    questionmark: QuestionmarkIcon,
    location: LocationIcon,
    locationfilled: LocationFilled,
    whatsapp: SocialMediaIcon + "#whatsapp",
    phone: Phone,
    mail: Mail,
    clock: ClockIcon,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/FooterDynamic/footerDynamic.scss";
</style>