<template>
  <ul class="list" :class="classes">
    <slot />
  </ul>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/UnorderedList/unorderedList.scss";
</style>
