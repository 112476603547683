import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store";
import { IS_USER_AUTHENTICATED_GETTER } from "@/store/storeconstants";

const Stepper = () => import('@/components/containers/Stepper/Stepper.vue');
const LoginPage = () => import('@/components/organisms/LoginPage/LoginPage.vue');

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/ordering-tool',
        name: 'stepper',
        component: Stepper,
        meta: { auth: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if ('auth' in to.meta &&
        to.meta.auth &&
        !store.getters[`auth/${IS_USER_AUTHENTICATED_GETTER}`]) {
        next('/login')
    } else if ('auth' in to.meta &&
        !to.meta.auth &&
        store.getters[`auth/${IS_USER_AUTHENTICATED_GETTER}`]) {
        next('/ordering-tool')
    } else {
        next();
    }
})

export default router