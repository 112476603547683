import mutations from '@/store/modules/app/mutations'
import getters from '@/store/modules/app/getters'
import actions from '@/store/modules/app/actions'

export default {
    namespaced: true,
    state() {
        return {
            //STEP 1
            brands_list: [],
            model_list: [],
            charging_units: '',
            costs: '',
            brand: 'alle merken',
            model: 'alle series',
            version: 'alle versies',
            //STEP 2
            charger_position: '',
            charging_cable: '',
            //STEP 3
            charging_time: '',
            phase: '',
            amperes: '',
            loadbalancing: '',
            chargePlug: '',
            //STEP 4
            productCardList: [],
            cablesArray: [],
            additionalAccessoriesArray: [],
            selectedProduct: '',
            selectedCabel: '',
            selectedAccessories: '',
        };
    },
    mutations,
    getters,
    actions,
}