import { SET_CHARGING_TIME, SET_FIRST_STEP_CHOICES, SET_FOURTH_STEP_CHOICES, SET_MODEL_BRAND_VERSION, SET_PRODUCT_ACCESSORIES, SET_PRODUCT_CARD_LIST, SET_SECOND_STEP_CHOICES, SET_THIRD_STEP_CHOICES, SET_VEHICLE_BRANDS_LIST, SET_VEHICLE_MODEL_LIST, SORT_PRODUCTS } from "@/store/storeconstants";

export default {
    [SORT_PRODUCTS](state, payload) {
        state.productCardsArray = payload;
    },

    [SET_FOURTH_STEP_CHOICES](state, payload) {
        state.selectedProduct = payload.selectedProduct;
        state.selectedCables = payload.selectedCables;
        state.selectedAccessories = payload.selectedAccessories;
    },

    [SET_PRODUCT_ACCESSORIES](state, payload) {
        state.cablesArray = payload.cablesArray;
        state.additionalAccessoriesArray = payload.additionalAccessoriesArray;
    },

    [SET_PRODUCT_CARD_LIST](state, payload) {
        state.productCardList = payload;
    },

    [SET_VEHICLE_BRANDS_LIST](state, payload) {
        state.brands_list = payload.data;
    },

    [SET_VEHICLE_MODEL_LIST](state, payload) {
        state.model_list = payload.data;
    },

    [SET_MODEL_BRAND_VERSION](state, payload) {
            state.model = payload.model;
            state.brand = payload.brand;
            state.version = payload.version;
    },

    [SET_CHARGING_TIME](state, payload) {
        state.charging_time = payload;
    },

    [SET_FIRST_STEP_CHOICES](state, payload) {
        state.charging_units = payload.charging_units;
        state.costs = payload.costs;
    },

    [SET_SECOND_STEP_CHOICES](state, payload) {
        state.charger_position = payload.charger_position;
        state.charging_cable = payload.charging_cable;
    },

    [SET_THIRD_STEP_CHOICES](state, payload) {
        state.phase = payload.phase;
        state.amperes = payload.amperes;
        state.loadbalancing = payload.loadbalancing;
        state.chargePlug = payload.chargePlug;
    },
};
