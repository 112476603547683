<template>
  <div class="stepper">
    <div class="stepper_progress">
      <div
        class="stepper_progress-bar"
        :style="'width:' + stepperProgress"
      ></div>
    </div>

    <div
      class="stepper_item"
      :class="{ current: step == item.stepNo, success: step > item.stepNo }"
      v-for="item in stepperArray"
      :key="item.id"
    >
      <div class="stepper_item-counter">
        <img
          class="stepper_item-icon"
          :class="
            step == item.stepNo
              ? 'stepper_item-icon--' + item.id + '-green'
              : 'stepper_item-icon--' + item.id
          "
          :src="
            step == item.stepNo
              ? icons + '#' + item.id + '-green'
              : icons + '#' + item.id
          "
          :alt="item.title"
        />
      </div>
      <p class="stepper_item-title">{{ item.title }}</p>
    </div>
  </div>

  <div class="stepper_pane" v-if="step == 1">
    <Step1 @submitstep1="step1submitted" />
  </div>

  <div class="stepper_pane" v-if="step == 2">
    <Step2 @submitstep2="step2submitted" />
  </div>

  <div class="stepper_pane" v-if="step == 3">
    <Step3 @submitstep3="step3submitted" />
  </div>

  <div class="stepper_pane" v-if="step == 4">
    <Step4 @submitstep4="step4submitted" />
  </div>

  <div class="stepper_pane" v-if="step == 5">
    <Step5 @submitstep5="step5submitted" />
  </div>
</template>

<script>
import StepsSprite from "@/assets/icons/steps-sprite.svg";
import Step1 from "@/components/organisms/Step1/Step1.vue";
import Step2 from "@/components/organisms/Step2/Step2.vue";
import Step3 from "@/components/organisms/Step3/Step3.vue";
import Step4 from "@/components/organisms/Step4/Step4.vue";
import Step5 from "@/components/organisms/Step5/Step5.vue";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  data: () => ({
    step: 1,
    radioCardsArray: [
      {
        image: "https://picsum.photos/200",
        imageAlt: "img alt",
        option: "Woonhuis",
        radioName: "test",
      },
      {
        image: "https://picsum.photos/200",
        imageAlt: "img alt",
        option: "Bedrijfspand",
        radioName: "test",
      },
    ],
    stepperArray: [
      {
        id: "step-1",
        title: "situatie",
        stepNo: 1,
      },
      {
        id: "step-2",
        title: "plaatsing",
        stepNo: 2,
      },
      {
        id: "step-3",
        title: "aansluiting",
        stepNo: 3,
      },
      {
        id: "step-4",
        title: "selectie",
        stepNo: 4,
      },
      {
        id: "step-5",
        title: "controle",
        stepNo: 5,
      },
    ],
    icons: StepsSprite,
  }),
  methods: {
    step1submitted() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    step2submitted() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    step3submitted() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    step4submitted() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    step5submitted() {
      this.step++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    stepperProgress() {
      let progress = (100 / 4.2) * (this.step - 1);
      return `calc(15px + ${progress}%`;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>

<style lang="scss">
@import "@/components/containers/Stepper/stepper.scss";
</style>