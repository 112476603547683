<template>
  <div class="login-page_banner"></div>

  <div class="login-page_description-wrapper">
    <div class="login-page_description">
      <p class="login-page_text">
        Deze laadpalen keuzehulp helpt u en uw klant om de juiste laadunit en bijbehorende producten te vinden. Op basis van de situatie en wensen vindt u direct een selectie van geschikte producten.
      </p>
      <p class="login-page_text">
        Voeg daarna simpel uw keuzes toe aan uw winkelwagen in de webshop.
      </p>
    </div>

    <div class="login-page_description">
      <Accordion
        title="Handig om bij de hand te hebben"
        class="login-page_accordion-list"
      >
        <UnorderedList>
          <li>Het merk en serie van de auto waarvoor de laadunit bestemd is.</li>
          <li>
            Informatie over de aansluiting op locatie (zoals het aantal fase en ampère van de meterkast en over de wens voor dynamisch load balancing.
          </li>
          <li>Log in met uw Technische Unie-gegevens en ga aan de slag!
          </li>
        </UnorderedList>
      </Accordion>
      <div class="login-page_list">
        <h3 class="login-page_title title--green">
          Handig om bij de hand te hebben
        </h3>
        <UnorderedList>
          <li>Het merk en serie van de auto waarvoor de laadunit bestemd is.</li>
          <li>
            Informatie over de aansluiting op locatie (zoals het aantal fase en ampère van de meterkast en over de wens voor dynamisch load balancing.
          </li>
          <li>Log in met uw Technische Unie-gegevens en ga aan de slag!
          </li>
        </UnorderedList>
      </div>
    </div>

    <div class="login-page_description">
      <p class="login-page_text--bold">
        Voor u aan de slag kunt moet u eerst
        <Link
          url="#"
          text="inloggen"
          class="login-page_link login-page_link--bold"
          :class="isAuthenticated ? 'login-page_link--disabled' : ''"
          @click="toggleModal"
        />
        met uw Technische Unie inlog gegevens.
      </p>
      <div class="login-page_button-wrapper" @click="clickedDisabledButton()">
        <Button
          buttonText="Start"
          buttonVariation="login-page_button button--primary"
          :disableButton="!isAuthenticated"
          @clickedBtn="startOrdering"
        />
      </div>
    </div>
  </div>
  <Modal
    :show="showModal"
    @close="closeModal"
    @clickbackgdrop="closeModal"
    :closeButton="showModalCloseButton"
  >
    <LoginForm
      @goback="toggleModal"
      @username="filledUsername"
      @customerNo="filledNumber"
      @password="filledPassword"
      :loginDisabled="disableLoginButton"
      @submitedLogin="handleSubmit"
      :accNotFoundError="error"
      :loginFailed="error"
      :autoLoggedOutWarning="autoLoggedOut"
      :showLoader="showLoading"
    />
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  IS_USER_AUTHENTICATED_GETTER,
  LOGIN_ACTION,
  GET_AUTOLOGOUT,
} from "@/store/storeconstants";

import Button from "@/components/atoms/Button/Button.vue";
import UnorderedList from "@/components/atoms/UnorderedList/UnorderedList.vue";
import Link from "@/components/atoms/Link/Link.vue";
import LoginForm from "@/components/molecules/LoginForm/LoginForm.vue";
import Modal from "@/components/containers/Modal/Modal.vue";
import Accordion from "@/components/molecules/Accordion/Accordion.vue";

export default {
  components: {
    Button,
    UnorderedList,
    Link,
    LoginForm,
    Modal,
    Accordion,
  },
  data() {
    return {
      showModal: false,
      showModalCloseButton: true,
      customerNumber: null,
      username: "",
      password: "",
      disableLoginButton: true,
      disabledStartButton: true,
      error: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: IS_USER_AUTHENTICATED_GETTER,
      autoLoggedOut: GET_AUTOLOGOUT,
    }),
    ...mapState({
      showLoading: (state) => state.showLoading,
    }),
  },
  methods: {
    ...mapActions("auth", {
      login: LOGIN_ACTION,
    }),
    toggleModal() {
      if (this.isAuthenticated == false) {
        this.showModal = !this.showModal;
        this.error = false;

        if (this.showModal == true) {
          document.getElementsByTagName("body")[0].style = "overflow: hidden;";
        } else {
          document.getElementsByTagName("body")[0].style = "overflow: auto;";
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    },
    checkScreenSize() {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 511) {
        this.showModalCloseButton = false;
      } else if (screenWidth >= 512) {
        this.showModalCloseButton = true;
      } else {
        this.showModalCloseButton = true;
      }
    },
    filledUsername(username) {
      this.username = username;
    },
    filledNumber(customerNo) {
      this.customerNumber = customerNo;
    },
    filledPassword(password) {
      this.password = password;
    },
    enableLoginBtn() {
      this.disableLoginButton = false;
    },
    handleSubmit() {
      //login check
      this.login({
        customerNumber: this.customerNumber,
        username: this.username,
        password: this.password,
      }).catch((e) => {
        this.error = e;
      });
    },
    clickedDisabledButton() {
      if (this.disabledStartButton == true) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.toggleModal();
      }
    },
    startOrdering() {
      this.$router.push("/ordering-tool");
    },
  },
  mounted() {
    this.checkScreenSize();

    window.addEventListener(
      "resize",
      function (event) {
        this.checkScreenSize();
      }.bind(this),
      true
    );
  },
  watch: {
    password() {
      if (
        this.customerNumber != null &&
        this.username.length > 0 &&
        this.password.length > 0
      ) {
        this.enableLoginBtn(this.customerNumber, this.username, this.password);
      }
    },
    username() {
      if (
        this.customerNumber != null &&
        this.username.length > 0 &&
        this.password.length > 0
      ) {
        this.enableLoginBtn(this.customerNumber, this.username, this.password);
      }
    },
    customerNumber() {
      if (
        this.customerNumber != null &&
        this.username.length > 0 &&
        this.password.length > 0
      ) {
        this.enableLoginBtn(this.customerNumber, this.username, this.password);
      }
    },
    isAuthenticated() {
      this.disabledStartButton = !this.isAuthenticated;

      if (this.isAuthenticated) {
        this.closeModal();
        this.$router.push("/ordering-tool");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/components/organisms/LoginPage/loginPage.scss";
</style>