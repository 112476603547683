import {
    GET_ADDITIONAL_ACCESSORIES_LIST,
    GET_AMPERES,
    GET_CABLES_LIST,
    GET_CABLE_TYPE,
    GET_CHARGER_POSITION,
    GET_CHARGING_CABLE,
    GET_CHARGING_TIME,
    GET_COSTS,
    GET_LOADBALANCING,
    GET_PAHSE,
    GET_PRODUCT_CARD_LIST,
    GET_SELECTED_ACCESSORIES,
    GET_SELECTED_CABEL,
    GET_SELECTED_CABLES,
    GET_SELECTED_PRODUCT,
    GET_VEHICLE_BRAND,
    GET_VEHICLE_BRANDS_LIST,
    GET_VEHICLE_CHARGING_UNITS,
    GET_VEHICLE_MODEL,
    GET_VEHICLE_MODEL_LIST,
    GET_VEHICLE_VERSION
} from "@/store/storeconstants";

export default {
    [GET_CABLE_TYPE]: (state) => {
        return state.chargePlug
    },
    [GET_SELECTED_CABEL]: (state) => {
        return state.selectedCabel
    },
    [GET_SELECTED_CABLES]: (state) => {
        return state.selectedCables
    },
    [GET_SELECTED_ACCESSORIES]: (state) => {
        return state.selectedAccessories
    },
    [GET_SELECTED_PRODUCT]: (state) => {
        return state.selectedProduct
    },
    [GET_ADDITIONAL_ACCESSORIES_LIST]: (state) => {
        return state.additionalAccessoriesArray
    },
    [GET_CABLES_LIST]: (state) => {
        return state.cablesArray
    },
    [GET_PRODUCT_CARD_LIST]: (state) => {
        return state.productCardList
    },
    [GET_VEHICLE_MODEL_LIST]: (state) => {
        return state.model_list
    },
    [GET_VEHICLE_BRANDS_LIST]: (state) => {
        return state.brands_list
    },
    [GET_VEHICLE_MODEL]: (state) => {
        return state.model
    },
    [GET_VEHICLE_BRAND]: (state) => {
        return state.brand
    },
    [GET_VEHICLE_VERSION]: (state) => {
        return state.version
    },
    [GET_CHARGING_TIME]: (state) => {
        return state.charging_time
    },
    [GET_VEHICLE_CHARGING_UNITS]: (state) => {
        return state.charging_units
    },
    [GET_COSTS]: (state) => {
        return state.costs
    },
    [GET_CHARGER_POSITION]: (state) => {
        return state.charger_position
    },
    [GET_CHARGING_CABLE]: (state) => {
        return state.charging_cable
    },
    [GET_PAHSE]: (state) => {
        return state.phase
    },
    [GET_AMPERES]: (state) => {
        return state.amperes
    },
    [GET_LOADBALANCING]: (state) => {
        return state.loadbalancing
    },
};