<template>
  <div class="message-card_wrapper" :class="classes">
    <Image :src="messageIcon" alt="img" classes="image--message" />
    <div class="message-card_description-wrapper">
      <p class="message-card_description">{{ description }}</p>
      <p class="message-card_description" v-if="descriptionSecondLine">
        {{ descriptionSecondLine }}
      </p>
    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import WarningIcon from "@/assets/icons/warning.svg";
import InfoIcon from "@/assets/icons/info.svg";
import ErrorIcon from "@/assets/icons/error.svg";

export default {
  components: {
    Image,
  },
  props: {
    classes: {
      type: String,
      required: false,
      default: "message-card--info",
    },
    description: {
      type: String,
      required: true,
    },
    descriptionSecondLine: {
      type: String,
      required: false,
    },
    messageType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    warningIcon: WarningIcon,
    infoIcon: InfoIcon,
    errorIcon: ErrorIcon
  }),
  computed: {
    messageIcon: function() {
      if (this.messageType == "warning") {
        return this.warningIcon;
      } else if (this.messageType == "info") {
        return this.infoIcon;
      } else if (this.messageType == "error") {
        return this.errorIcon;
      } else {
        return this.warningIcon;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/MessageCard/messageCard.scss";
</style>