import mutations from '@/store/modules/auth/mutations'
import getters from '@/store/modules/auth/getters'
import actions from '@/store/modules/auth/actions'

export default {
    namespaced: true,
    state() {
        return {
            access_token: '',
            token_type: '',
            expires_in: '',
            autoLogout: false,
            username: '',
            current_time: '',
            customer_number: ''
        };
    },
    mutations,
    getters,
    actions,
}
