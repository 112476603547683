<template>
  <div class="radio-button-card-groups_wrapper">
    <h3 class="radio-button-card-groups_title">{{ title }}</h3>
    <p class="radio-button-card-groups_description" v-if="description">
      {{ description }}
      <Link
        url="javascript:void(0)"
        text="Meer informatie"
        @clickedlink="linkclicked"
      />
    </p>

    <div v-for="object in optionsArray" :key="object.title">
      <RadioButtonCard
        :cardImage="object.image"
        :cardImageAlt="object.imageAlt"
        :cardRadioOptions="object.option"
        :radioName="object.radioName"
        :radioValue="object.radioValue"
        @checkedOption="checkedRadioOtion"
      />
    </div>
    <div
      class="radio-button-card-groups_charging-speed-wrapper"
      v-if="chargingSpeed"
    >
      <h3 class="radio-button-card-groups_subtitle" v-if="chargingTimeInHours">
        Laadsnelheid
      </h3>
      <div
        class="radio-button-card-groups_charging-speed-content"
        v-if="chargingTimeInHours"
      >
        <img
          class="radio-button-card-groups_charging-speed-icon"
          :src="clockIcons + '#' + chargingTimeIcon"
          alt="charging-speed"
        />
        <p
          class="radio-button-card-groups_charging-speed"
          v-if="chargingTimeInHours"
        >
          {{ chargingTimeInHours }} uur
          <Link
            class="radio-button-card-groups_charging-speed-link"
            url="javascript:void(0)"
            text="Meer informatie"
            @clickedlink="link3clicked"
          />
        </p>
      </div>
    </div>
    <MessageCard
      v-if="warningMessage"
      messageType="warning"
      classes="message-card--warning"
      :description="warningMessage"
    />
  </div>
</template>

<script>
import RadioButtonCard from "@/components/molecules/RadioButtonCard/RadioButtonCard.vue";
import Link from "@/components/atoms/Link/Link.vue";
import ClockSprite from "@/assets/icons/clock-sprite.svg";
import MessageCard from "@/components/molecules/MessageCard/MessageCard.vue";

export default {
  emits: ["checkedRadioOption", "clickedlink", "clickedlink2", "clickedlink3"],
  components: {
    RadioButtonCard,
    Link,
    MessageCard,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    optionsArray: {
      type: Array,
      default: () => [],
    },
    chargingSpeed: {
      type: Boolean,
      required: false,
    },
    chargingTimeInHours: {
      type: String,
      required: false,
    },
    chargingTimeIcon: {
      type: String,
      required: false,
      default: "clock-15",
    },
    warningMessage: {
      type: String,
      required: false,
    },

  },
  data: () => ({
    clockIcons: ClockSprite,
  }),
  methods: {
    checkedRadioOtion(checked) {
      this.$emit("checkedRadioOption", checked);
    },
    linkclicked() {
      this.$emit("clickedlink");
    },
    link2clicked() {
      this.$emit("clickedlink2");
    },
     link3clicked() {
      this.$emit("clickedlink3");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/organisms/RadioButtonCardGroups/radioButtonCardGroups.scss";
</style>
