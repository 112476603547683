<template>
  <div
    class="dropdown-list_outer-wrapper"
    :class="isOval ? 'dropdown-list--oval' : ''"
  >
    <p class="dropdown-list_label" v-if="label">{{ label }}</p>
    <div
      :id="id"
      class="dropdown-list_wrapper"
      :class="ddClass"
      :data-value="value"
      :data-list="optionsList"
      ref="dropdownRef"
    >
      <div
        class="dropdown-list_selector"
        @click="toggle()"
        :class="{ selected: optionsList.includes(selectedValue) }"
      >
        <div class="dropdown-list_placeholder">
          <span>{{ selectedValue == "" ? value : selectedValue }}</span>
        </div>
        <div class="dropdown-list_border"></div>
        <div class="dropdown-list_arrow" :class="{ expanded: visible }"></div>
        <div :class="{ hidden: !visible, visible }">
          <ul class="dropdown-list_ul">
            <li
              class="dropdown-list_li"
              v-for="option in optionsList"
              @click="select(option)"
              :key="option"
            >
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selectedOption", "toggledd"],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    optionsList: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
    },
    ddClass: {
      type: String,
      required: false,
    },
    checkSelection: {
      type: String,
      required: false,
    },
    forceClose: {
      type: Boolean,
      required: false,
    },
    isOval: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectedValue: "",
      visible: false,
      tempOptionList: [],
    };
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
      this.$emit("toggledd");
    },
    select(option) {
      this.tempOptionList = this.optionsList;

      if (this.optionsList.includes(option)) {
        this.selectedValue = option;
      } else {
        this.selectedValue = "";
      }

      this.$emit("selectedOption", option);
    },
    closeDropdown() {
      this.visible = false;
    },
  },
  mounted() {
    document.addEventListener(
      "click",
      function (event) {
        if (event.target.closest(".dropdown-list_wrapper")) return;
        this.closeDropdown();
      }.bind(this)
    );
  },
  watch: {
    optionsList() {
      if (
        Array.from(this.tempOptionList).toString() !==
        this.optionsList.toString()
      ) {
        this.selectedValue = this.value;
      }
    },
    forceClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/components/atoms/DropdownList/dropdownList.scss";
</style>