<template>
  <div class="horizontal-card_wrapper horizontal-card_wrapper--select horizontal-card_wrapper--product">
    <CheckBox
      class="horizontal-card_checkbox--bottom"
      :options="[cardOptions]"
      :checkboxName="checkerName"
      :showLabel="false"
      @checked="checkedCard"
    />
    <span class="horizontal-card_message">Vergelijk producten</span>
    <div class="horizontal-card_image-wrapper">
      <Image
        class="horizontal-card_image image--horizontal-card"
        :src="cardImage != '' ? cardImage : defaultImage"
        :alt="cardImageAlt"
      />
    </div>
    <div class="horizontal-card_product-info-wrapper">
      <p class="horizontal-card_title">
        {{ title }}
      </p>
      <p class="horizontal-card_subtitle">{{ subtitle }}</p>
      <div class="horizontal-card_details-outer-wrapper">
        <div class="horizontal-card_article-details-wrapper">
          <div class="horizontal-card_product-details-article-wrapper">
            <span class="horizontal-card_item-details">TU Artikel nr:</span
            ><span class="horizontal-card_item-details--bold">{{
              articleNumber
            }}</span>
          </div>
          <div class="horizontal-card_product-details-article-wrapper">

          </div>
        </div>
        <div class="horizontal-card_list-wrapper">
          <UnorderedList classes="list--swoosh" class="horizontal-card_list">
            <li>
              Nominaal aansluitvermogen:
              <span class="horizontal-card_list-item-value">{{ connectionCapacity != 'None' ? connectionCapacity + 'KWH'  : 'Onbekend'}}</span>
            </li>
            <li>
              Aansluiting uitgaand:
              <span class="horizontal-card_list-item-value">{{ plug != 'None' ? plug : 'Onbekend'}}</span>
            </li>
            <li>
              Soort toegangscontrole:
              <span class="horizontal-card_list-item-value">{{ accessControl != 'None' ? accessControl : 'Onbekend'}}</span>
            </li>
            <li>
              Load balancing:
              <span class="horizontal-card_list-item-value">{{ loadBalancing != 'None' ? loadBalancing : 'Onbekend'}}</span>
            </li>
            <li>
              Kleur:
              <span class="horizontal-card_list-item-value">{{ color != 'None' ? color : 'Onbekend'}}</span>
            </li>
          </UnorderedList>
        </div>
      </div>
    </div>
    <div class="horizontal-card_price-info-wrapper">
      <div
        class="horizontal-card_price-wrapper"
        :class="price == 'NoApiCall' ? 'hidden' : ''"
      >
        <p class="horizontal-card_price">€ {{ brutoPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR"
            })
          }} Bruto</p>
        <p class="horizontal-card_price"><b>€ {{ price.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR"
            })
          }} Netto</b></p>
        <div v-if="cardTag" class="horizontal-card_tag">{{ cardTag }}</div>
      </div>
      <div class="horizontal-card_availability-wrapper">
        <div :class="price == 'NoApiCall' ? 'hidden' : ''">
          <div class="horizontal-card_availability-inner-wrapper">
            <Image
              class="horizontal-card_check-icon image--vertical-card-check-icon"
              :src="quantityIcon"
              alt="icon"
            />
            <span :class="quantityClass"> Op voorraad: {{ loadQuantity() }}</span>
          </div>
        </div>
        <div class="horizontal-card_button" @click="selectBtn">Selecteer</div>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import CheckBox from "@/components/atoms/CheckBox/CheckBox.vue";
import UnorderedList from "@/components/atoms/UnorderedList/UnorderedList.vue";

import DefaultImage from "@/assets/images/default-image.png";
import StepsSprite from "@/assets/icons/steps-sprite.svg";
import WarningIcon from "@/assets/icons/warning.svg";

export default {
  emits: ["checkedproductcard", "selectedProduct"],
  components: {
    Image,
    CheckBox,
    UnorderedList,
  },
  props: {
    cardOptions: {
      type: String,
      required: false,
    },
    checkerName: {
      type: String,
      required: false,
    },
    cardImage: {
      type: String,
      required: false,
      default: DefaultImage,
    },
    cardImageAlt: {
      type: String,
      required: false,
      default: "card image",
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    articleNumber: {
      type: String,
      required: false,
    },
    suppliercode: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: true,
    },
    productQuantity: {
      type: String,
      required: false,
    },
    deliveryTimeDelay: {
      type: String,
      required: false,
      default: "0",
    },
    cardTag: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    depth: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    manageType: {
      type: String,
      required: false,
    },
    display: {
      type: Boolean,
      required: false,
    },
    solarPanelCompatible: {
      type: Boolean,
      required: false,
    },
    brutoPrice:{
      type: Number,
      required: false
    },
    cableLength: {
      type: String,
      required: false,
    },
    connectionCapacity: {
      type: String,
      required: false,
    },
    plug: {
      type: String,
      required: false,
    },
    accessControl: {
      type: String,
      required: false,
    },
    loadBalancing: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    quantityIcon: StepsSprite + "#step-5-green",
    quantityClass: "horizontal-card_availability--green",
    deliveryTime: "Morgen bezorgd",
    defaultImage: DefaultImage,
    item: {}
  }),
  methods: {
    checkedCard(checked) {
      this.$emit(
        "checkedproductcard",
        checked,
        this.cardOptions,
        this.checkerName,
        this.cardImage,
        this.cardImageAlt,
        this.title,
        this.subtitle,
        this.articleNumber,
        this.suppliercode,
        this.productQuantity,
        this.deliveryTimeDelay,
        this.cardTag,
        this.price,
        this.color,
        this.manageType,
        this.display,
        this.solarPanelCompatible,
        this.cableLength,
        this.loadBalancing,
        this.connectionCapacity,
        this.plug,
        this.accessControl,
        this.brutoPrice
      );
    },
    loadQuantity() {
      if (this.productQuantity > 0) {
        // show that product is available
        this.quantityIcon = StepsSprite + "#step-5-green";
        this.quantityClass = "horizontal-card_availability--green";
        this.deliveryTime = "Morgen bezorgd";
        return this.productQuantity;
      } else if (this.productQuantity <= 0) {
        // show text that product is not available and show delivery time in weeks
        this.quantityIcon = WarningIcon;
        this.quantityClass = "horizontal-card_availability--yellow";
        this.deliveryTime = this.deliveryTimeDelay;
        return "Niet op voorraad";
      }
    },
    selectBtn() {
      this.$emit("selectedProduct", this.item = {
        cardOptions: this.cardOptions,
        checkerName: this.checkerName,
        cardImage: this.cardImage,
        cardImageAlt: this.cardImageAlt,
        title: this.title,
        subtitle: this.subtitle,
        articleNumber: this.articleNumber,
        suppliercode: this.suppliercode,
        productQuantity: this.productQuantity,
        deliveryTimeDelay: this.deliveryTimeDelay,
        price: this.price,
        color: this.color,
        manageType: this.manageType,
        display: this.display,
        solarPanelCompatible: this.solarPanelCompatible,
        cableLength: this.cableLength,
        connectionCapacity: this.connectionCapacity,
        plug: this.plug,
        accessControl: this.accessControl,
        loadBalancing:this.loadBalancing,
        brutoPrice: this.brutoPrice,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/HorizontalCard/horizontalCard.scss";
</style>
