<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";
@import "@/scss/responsive";

.container {
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  background: $white;
  height: 100%;
  padding: 80px 60px;
  z-index: 10;

  @media (max-width: $breakpoint-l-max) {
    padding: 40px;
  }

  @media (max-width: $breakpoint-s-max) {
    padding: 40px 15px;
  }
}
</style>