import { SET_AUTO_LOGOUT_MUTATION, SET_USER_TOKEN_DATA_MUTATION } from "@/store/storeconstants";

export default {
    [SET_USER_TOKEN_DATA_MUTATION](state, payload) {
        state.access_token = payload.access_token;
        state.token_type = payload.token_type;
        state.expires_in = payload.expires_in;
        state.autoLogout = false;
        state.username = payload.username;
        state.current_time = payload.current_time;
        state.customer_number = payload.customer_number;
    },

    [SET_AUTO_LOGOUT_MUTATION](state) {
        state.autoLogout = true;
    }
};
