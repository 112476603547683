<template>
  <a
    :href="link"
    @click="$emit('clickedBtn')"
    class="plain-button"
    :class="[variations, disabled]"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    variations: {
      type: String,
      required: false,
    },
    disabled: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/PlainButton/plainButton.scss";
</style>
