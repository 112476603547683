import LoginValidation from "@/services/LoginValidations";
import {
    AUTH_ACTION,
    AUTO_LOGIN_ACTION,
    AUTO_LOGOUT_ACTION,
    LOADING_SPINNER_SHOW_MUTATION,
    LOGIN_ACTION,
    LOGOUT_ACTION,
    SET_AUTO_LOGOUT_MUTATION,
    SET_USER_TOKEN_DATA_MUTATION
} from "@/store/storeconstants";
import Axios from "axios";
import { URL } from "@/store/consts"

let timer = '';

export default {
    [LOGOUT_ACTION](context) {
        context.commit(SET_USER_TOKEN_DATA_MUTATION, {
            access_token: null,
            token_type: null,
            expires_in: null
        })

        localStorage.removeItem('userData');

        if (timer) {
            clearTimeout(timer);
        }
    },

    [AUTO_LOGOUT_ACTION](context) {
        context.dispatch(LOGOUT_ACTION);
        context.commit(SET_AUTO_LOGOUT_MUTATION);
    },

    async [LOGIN_ACTION](context, payload) {
        return context.dispatch(AUTH_ACTION, {
            ...payload,
            url: `${URL}/authentication`
        })

    },

    [AUTO_LOGIN_ACTION](context) {
        let userDataString = localStorage.getItem('userData');

        if (userDataString) {
            let refreshingTime = new Date().getTime()
            let userData = JSON.parse(userDataString);
            let expirationTime = userData.expires_in - (refreshingTime - userData.current_time);

            // AUTO LOGOUT on refresh if time left is less than 10s
            if (expirationTime < 10000) {
                context.dispatch(AUTO_LOGOUT_ACTION)
            } else {
                timer = setTimeout(() => {
                    context.dispatch(AUTO_LOGOUT_ACTION)
                }, expirationTime)

                context.commit(SET_USER_TOKEN_DATA_MUTATION, userData);
            }
        }
    },

    async [AUTH_ACTION](context, payload) {
        let postData = {
            account: payload.username,
            password: payload.password,
            loginid: payload.customerNumber,
        }

        let response = '';

        context.commit(LOADING_SPINNER_SHOW_MUTATION, true, {
            root: true
        });

        try {
            response = await Axios.post(
                payload.url,
                postData
            )
        } catch (err) {
            context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
                root: true
            });
            let errorMessage = LoginValidation.getErrorMessageFromCode(
                err.response.data,
            );
            throw errorMessage;
        }

        context.commit(LOADING_SPINNER_SHOW_MUTATION, false, {
            root: true
        });

        if (response.status === 200) {
            let expirationTime = +response.data.expires_in * 1000;
            // let expirationTime = +20 * 1000;

            let current_time = new Date().getTime();

            timer = setTimeout(() => {
                context.dispatch(AUTO_LOGOUT_ACTION);
            }, expirationTime);

            let tokenData = {
                access_token: response.data.access_token,
                token_type: response.data.token_type,
                expires_in: expirationTime,
                username: payload.username,
                customer_number: payload.customerNumber,
                current_time: current_time
            }

            // Save tokenData to local storage
            localStorage.setItem('userData', JSON.stringify(tokenData))
            context.commit(SET_USER_TOKEN_DATA_MUTATION, tokenData)
        }
    }
};
