<template>
  <div class="login-form_wrapper inner">
    <div class="login-form_go-back" @click="$emit('goback')">
      <img class="login-form_go-back-icon" :src="backArrow" alt="back arrow" />
      <p class="login-form_go-back-text">Terug</p>
    </div>
    <h2 class="login-form_title">Inloggen</h2>
    <MessageCard
      v-if="securityError"
      messageType="error"
      classes="message-card--error"
      description="Er is geprobeerd in te loggen met een combinatie die bij ons niet bekend is. Vul daarom eenmalig onderstaande veiligheidscheck in."
    />
    <MessageCard
      v-if="autoLoggedOutWarning"
      messageType="warning"
      classes="message-card--warning"
      description="Er is al even geen activiteit meer geregistreerd, u bent daarom uitgelogd. Log opnieuw in. "
    />
    <form @submit.prevent="handleSubmit">
      <InputField
        type="number"
        name="test"
        id="1"
        label="Klantnummer (verplicht)"
        placeholder="Bestaat uit 7 cijfers"
        @input="filledNumber"
        :success="numberInputFilled"
        :error="loginFailed"
      />
      <InputField
        type="text"
        name="test"
        id="2"
        label="Gebruiker"
        placeholder="Hoofdlettergevoelig, cijfers mogelijk"
        @input="filledUsername"
        :success="usernameInputFilled"
        :error="loginFailed"
      />
      <PasswordInput
        name="test"
        id="3"
        label="Wachtwoord (verplicht)"
        placeholder="Wachtwoord"
        @input="filledPassword"
        :success="passwordInputFilled"
        :error="loginFailed"
      />

      <MessageCard
        v-if="accNotFoundError"
        messageType="error"
        classes="message-card--error"
        description="De combinatie van gebruikersnaam, klantnummer en wachtwoord herkennen we niet. Probeer het nog eens."
      />

      <div class="login-form_links-wrapper">
        <Link
          url="https://www.technischeunie.nl/lp/inloggegevens"
          text="Inloggegevens/wachtwoord vergeten"
          class="login-form_link"
        />
      </div>

      <div v-if="showLoader" class="login-form_loader-wrapper">
        <Loader v-if="showLoader" />
      </div>

      <Button
        type="submit"
        buttonText="Inloggen"
        buttonVariation="login-form_button button--primary"
        :disableButton="loginDisabled"
      />
    </form>
    <div class="login-form_create-acc-wrapper">
      <span class="login-form_span"> Nog geen klant?</span>
      <PlainButton
        text="Klant worden"
        variations="plain-button--underline"
        link="https://www.technischeunie.nl/klant-worden-bij-technische-unie/"
      />
    </div>
  </div>
</template>

<script>
import InputField from "@/components/atoms/InputField/InputField.vue";
import PasswordInput from "@/components/atoms/PasswordInput/PasswordInput.vue";
import Button from "@/components/atoms/Button/Button.vue";
import Link from "@/components/atoms/Link/Link.vue";
import Loader from "@/components/atoms/Loader/Loader.vue";
import MessageCard from "@/components/molecules/MessageCard/MessageCard.vue";
import PlainButton from "@/components/atoms/PlainButton/PlainButton.vue";

import BackArrow from "@/assets/icons/arrow-back-black.svg";

export default {
  emits: ["goback", "username", "customerNo", "password", "submitedLogin"],
  components: {
    InputField,
    Button,
    Link,
    Loader,
    MessageCard,
    PasswordInput,
    PlainButton,
  },
  props: {
    securityError: {
      type: Boolean,
      required: false,
      default: false,
    },
    accNotFoundError: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoLoggedOutWarning: {
      type: Boolean,
      required: false,
      default: false,
    },
    loginDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loginFailed: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLoader: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    backArrow: BackArrow,
    numberInputFilled: false,
    usernameInputFilled: false,
    passwordInputFilled: false,
  }),
  methods: {
    filledUsername(username) {
      this.$emit("username", username);

      if (username.length > 0) {
        this.usernameInputFilled = true;
      } else if (username.length <= 0) {
        this.usernameInputFilled = false;
      }
    },
    filledNumber(customerNo) {
      this.$emit("customerNo", customerNo);
      this.numberInputFilled = true;

      if (customerNo.length > 0) {
        this.numberInputFilled = true;
      } else if (customerNo.length <= 0) {
        this.numberInputFilled = false;
      }
    },
    filledPassword(password) {
      this.$emit("password", password);
      this.passwordInputFilled = true;

      if (password.length > 0) {
        this.passwordInputFilled = true;
      } else if (password.length <= 0) {
        this.passwordInputFilled = false;
      }
    },
    handleSubmit() {
      this.$emit("submitedLogin");
    },
  },
};
</script>

<style lang="scss">
@import "@/components/molecules/LoginForm/loginForm.scss";
</style>