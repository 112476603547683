<template>
  <label class="radio-button-card_wrapper" :for="cardRadioOptions" 
  :class="this.disabled">
    <Tooltip v-if="tooltip" :text="tooltip" />
    <Image
      class="radio-button-card_image image--radio-card"
      :src="cardImage"
      :alt="cardImageAlt"
    />
    <RadioButton
      :radioOptions="[cardRadioOptions]"
      labelPosition="radio-button--label-above"
      :radioName="radioName"
      :radioValue="radioValue"
      @checkedRadio="checkedOption"
    />
  </label>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import RadioButton from "@/components/atoms/RadioButton/RadioButton.vue";
import Tooltip from "@/components/molecules/Tooltip/Tooltip.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_VEHICLE_CHARGING_UNITS,
} from "@/store/storeconstants";

export default {
  emits: ["checkedOption"],
  components: {
    Image,
    RadioButton,
    Tooltip,
  },
  props: {
    cardImage: {
      type: String,
      required: true,
    },
    cardImageAlt: {
      type: String,
      required: false,
    },
    cardRadioOptions: {
      type: String,
      required: false,
    },
    radioName: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    radioValue: {
      type: String,
      required: false
    },
  },
  data: () => ({
    disabled: "",
  }),
  methods: {
    checkedOption(checked) {
      this.$emit("checkedOption", checked);
    },
  },
  computed: {
     ...mapGetters("app", {
      vehicleChargingUnits: GET_VEHICLE_CHARGING_UNITS,
    }),
  },
  mounted() {
    if(this.radioValue === "1 fase" && this.vehicleChargingUnits === "2"){
      this.disabled="disabled";
    }else{
       this.disabled="";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/RadioButtonCard/radioButtonCard.scss";
</style>