<template>
  <SelectionResults
    title="Controleer uw keuzes"
    :selectedMark="selectedMark"
    :selectedSeries="selectedSeries"
    :selectedUnitsNumber="selectedUnitsNumber"
    :selectedCostOption="selectedCostOption"
    :selectedChargerPosition="selectedChargerPosition"
    :selectedChargingCable="selectedChargingCable"
    :selectedPhases="selectedPhases"
    :selectedAmpere="selectedAmpere"
    :selectedLoadbalancing="selectedLoadbalancing"
  />

  <div class="step-5_title-wrapper">
    <h2 class="title--green">Laadunits en bijbehorende producten</h2>
    <!-- <Link class="step-5_link" url="#" text="Aanpassen" /> -->
  </div>
  <div class="step-5_product-cable-cards-wrapper">
    <HorizontalCard
      :radioButton="false"
      :cardImage="selectedPruduct.image"
      :cardImageAlt="selectedPruduct.imageAlt"
      :title="selectedPruduct.title"
      :subtitle="selectedPruduct.subtitle"
      :articleNumber="selectedPruduct.articleNumber"
      :suppliercode="selectedPruduct.suppliercode"
      :productQuantity="selectedPruduct.productQuantity"
      :deliveryTimeDelay="selectedPruduct.deliveryTimeDelay"
      :price="selectedPruduct.price"
      :brutoPrice="selectedPruduct.brutoPrice"
    />
    <div v-for="selectedCabel in selectedCables">
      <HorizontalCard
        v-if="selectedCabel.articleNumber"
        :radioButton="false"
        :cardImage="selectedCabel.image"
        :cardImageAlt="selectedCabel.imageAlt"
        :title="selectedCabel.title"
        :subtitle="selectedCabel.subtitle"
        :articleNumber="selectedCabel.articleNumber"
        :suppliercode="selectedCabel.suppliercode"
        :productQuantity="selectedCabel.productQuantity"
        :deliveryTimeDelay="selectedCabel.deliveryTimeDelay"
        :price="selectedCabel.price"
        :brutoPrice="selectedCabel.brutoPrice"
      />
    </div>
  </div>

  <div class="step-5_title-wrapper" v-if="selectedAccessories.length > 0">
    <h2 class="title--green">Bijbehorende sakjdhakjsdhkja</h2>
    <!-- <Link class="step-5_link" url="#" text="Aanpassen" /> -->
  </div>

  <div
    class="step-5_product-cable-cards-wrapper"
    v-for="item in selectedAccessories"
    :key="item.title"
  >

    <HorizontalCard
      :radioButton="false"
      :cardImage="item.cardImage"
      :cardImageAlt="item.imageAlt"
      :title="item.title"
      :subtitle="item.subtitle"
      :articleNumber="item.articleNumber"
      :suppliercode="item.suppliercode"
      :productQuantity="item.productQuantity"
      :deliveryTimeDelay="item.deliveryTimeDelay"
      :price="item.price"
      :brutoPrice="item.brutoPrice"
    />
  </div>

  <div
    class="step-5_price-title-wrapper"
    v-if="calculateTotalPrice() != 'NoApiCall'"
  >
    <h2 class="step-5_price-title title--green">Totale nettoprijs</h2>
    <span class="step-5_text">(excl. BTW) </span>
  </div>
  <span class="step-5_price" v-if="calculateTotalPrice() != 'NoApiCall'"
    >€ {{ calculateTotalPrice() }}</span
  >

  <div class="step-5_buttons-wrapper">
    <Button
      class="step-5_button button-controle"
      @clickedBtn="addToCart"
      buttonText="Voeg toe aan winkelwagen"
      buttonVariation="button--primary"
      :disableButton="false"
    />
  </div>
  <MessageCard
    messageType="info"
    classes="message-card--info-borders step-5_info-message"
    description="U kunt deze artikelen aan Lijsten in uw Technische Unie account toevoegen en vervolgens via uw winkelwagen bestellen."
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ADD_TO_CART,
  GET_AMPERES,
  GET_CHARGER_POSITION,
  GET_CHARGING_CABLE,
  GET_COSTS,
  GET_LOADBALANCING,
  GET_PAHSE,
  GET_SELECTED_ACCESSORIES,
  GET_SELECTED_CABEL,
  GET_SELECTED_CABLES,
  GET_SELECTED_PRODUCT,
  GET_USERNAME,
  GET_VEHICLE_BRAND,
  GET_VEHICLE_CHARGING_UNITS,
  GET_VEHICLE_MODEL,
} from "@/store/storeconstants";

import Button from "@/components/atoms/Button/Button.vue";
import SelectionResults from "@/components/molecules/SelectionResults/SelectionResults.vue";
import Link from "@/components/atoms/Link/Link.vue";
import HorizontalCard from "@/components/molecules/HorizontalCard/HorizontalCard.vue";
import DownloadDropdown from "@/components/molecules/DownloadDropdown/DownloadDropdown.vue";
import MessageCard from "@/components/molecules/MessageCard/MessageCard.vue";

export default {
  emits: ["submitstep5"],
  components: {
    Button,
    SelectionResults,
    Link,
    HorizontalCard,
    DownloadDropdown,
    MessageCard,
  },
  computed: {
    ...mapGetters("app", {
      selectedMark: GET_VEHICLE_BRAND,
      selectedSeries: GET_VEHICLE_MODEL,
      selectedUnitsNumber: GET_VEHICLE_CHARGING_UNITS,
      selectedCostOption: GET_COSTS,
      selectedChargerPosition: GET_CHARGER_POSITION,
      selectedChargingCable: GET_CHARGING_CABLE,
      selectedPhases: GET_PAHSE,
      selectedAmpere: GET_AMPERES,
      selectedLoadbalancing: GET_LOADBALANCING,
      selectedPruduct: GET_SELECTED_PRODUCT,
      selectedAccessories: GET_SELECTED_ACCESSORIES,
      selectedCabel: GET_SELECTED_CABEL,
      selectedCables: GET_SELECTED_CABLES
    }),
    ...mapGetters("auth", {
      user: GET_USERNAME,
    }),
  },
  data: () => ({
    totalPrice: "",
    totalPriceCount: "",
  }),
  methods: {
    ...mapActions("app", {
      addToCartAction: ADD_TO_CART,
    }),
    calculateTotalPrice() {
      let productPrice = parseFloat(this.selectedPruduct.price);
      let cabelPrice = 0;
      this.selectedCables.forEach((item) => {
        cabelPrice += parseFloat(item.price);
      });
      let additionalAccessoriesPrice = 0;

      this.selectedAccessories.forEach((item) => {
        additionalAccessoriesPrice += parseFloat(item.price);
      });

      this.totalPrice = productPrice + cabelPrice + additionalAccessoriesPrice;
      if (this.totalPrice >= 0) {
        return this.totalPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR",
            });
      } else {
        return "NoApiCall";
      }
    },
    addToCart() {
      this.addToCartAction({
        selectedPruduct: this.selectedPruduct,
        selectedAccessories: this.selectedAccessories,
        selectedCables: this.selectedCables,
        username: this.user
      });

      this.$emit("submitstep5");
    },
  },
};
</script>

 <style lang="scss" scoped>
@import "@/components/organisms/Step5/step5.scss";
</style>
