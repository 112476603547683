<template>
  <div class="tooltip" >
    <div class="tooltip_container">

      <div class="tooltip_wrapper" :class="{ active: isVisible }">
        <div class="tooltip_text">
          {{ text }}
        </div>
        <div class="tooltip_point--white" />
        <div class="tooltip_point--green" />
      </div>

      <div class="tooltip_icon">
        <Image
          :src="require('@/assets/icons/tooltip.svg')"
          alt="img"
          classes="image--tooltip"
        />
      </div>

    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";

export default {
  name: "Tooltip",
  components: {
    Image,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    text: {
      type: String,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/Tooltip/tooltip.scss";
</style>
