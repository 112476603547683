<template>
  <h2 class="title--green">Plaatsing</h2>

  <RadioButtonCardGroups
    title="Wordt de laadunit bevestigd aan de wand of op een paal?"
    :optionsArray="chargerPosition"
    @checkedRadioOption="checkedchargerPosition"
    :warningMessage="checkedchargerPositionMessage"
  />
  <RadioButtonCardGroups
    title="Vaste laadkabel of socket?"
    description="Een laadunit met een vaste laadkabel is niet voor alle elektrische auto’s geschikt. In een socket passen alle laadkabels."
    :optionsArray="chargingCable"
    @checkedRadioOption="checkedChargingCable"
    @clickedlink="toggleModal"
  />
  <Button
    @clickedBtn="submit"
    buttonText="Naar aansluiting"
    buttonVariation="button--primary"
    :disableButton="disabledButton"
    class="button-plaatsing"
  />
  <Modal
    :modalPaddingNone="true"
    :show="showModal"
    @close="closeModal"
    @clickbackgdrop="closeModal"
    :closeButton="showModalCloseButton"
  >
    <MoreInformations title="Aansluiting uitgaand">
      <p>
       Er zijn laadpalen beschikbaar met een vaste kabel van bijvoorbeeld 5, 7 of 8 meter. Daarnaast bieden de meeste fabrikanten ook laadpalen aan met een socket (type 1 of 2), waarbij type 2 in de EU als standaard wordt gebruikt.
      </p>
    </MoreInformations>
  </Modal>
</template>

<script>
import { mapMutations } from "vuex";
import { SET_SECOND_STEP_CHOICES } from "@/store/storeconstants";

import RadioButtonCardGroups from "@/components/organisms/RadioButtonCardGroups/RadioButtonCardGroups.vue";
import Button from "@/components/atoms/Button/Button.vue";
import Modal from "@/components/containers/Modal/Modal.vue";
import MoreInformations from "@/components/molecules/MoreInformations/MoreInformations.vue";

import AttachedToWall from "@/assets/icons/attached-wall.svg";
import AttachedToGround from "@/assets/icons/attached-ground.svg";
import FixedCable from "@/assets/icons/fixed-cable.svg";
import SocketCable from "@/assets/icons/socket-cable.svg";

export default {
  emits: ["submitstep2"],
  components: {
    RadioButtonCardGroups,
    Button,
    Modal,
    MoreInformations,
  },
  data: () => ({
    step: 1,
    chargerPosition: [
      {
        image: AttachedToWall,
        imageAlt: "img",
        option: "Wand",
        radioName: "chargerPosition",
        radioValue: "Muur"
      },
      {
        image: AttachedToGround,
        imageAlt: "img",
        option: "Paal",
        radioName: "chargerPosition",
        radioValue: "Paal"
      },
    ],
    chargingCable: [
      {
        image: FixedCable,
        imageAlt: "img",
        option: "Vaste laadkabel",
        radioName: "chargingCable",
        radioValue: "Kabel met stekker type 1 of type 2"
      },
      {
        image: SocketCable,
        imageAlt: "img",
        option: "Socket",
        radioName: "chargingCable",
        radioValue: "Contactdoos type 2"
      },
    ],
    selectedChargerPosition: "",
    selectedChargingCable: "",
    checkedchargerPositionMessage: "",
    disabledButton: true,
    showModal: false,
    showModalCloseButton: true,
  }),
  methods: {
    ...mapMutations("app", {
      setSecondStepChoices: SET_SECOND_STEP_CHOICES,
    }),
    checkedchargerPosition(checked) {
      this.selectedChargerPosition = checked;
       if (this.selectedChargerPosition == "Paal") {
        this.checkedchargerPositionMessage =
          "Let op: selecteer een paal uit de toebehoren";
      } else {
        this.checkedchargerPositionMessage = "";
      }
      this.enableButton();
    },
    checkedChargingCable(checked) {
      this.selectedChargingCable = checked;
      this.enableButton();
    },
    enableButton() {
      if (
        this.selectedChargerPosition != "" &&
        this.selectedChargingCable != ""
      ) {
        this.disabledButton = false;
      }
    },
    submit() {
      this.$emit("submitstep2");
      this.setSecondStepChoices({
        charger_position: this.selectedChargerPosition,
        charging_cable: this.selectedChargingCable,
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;

      if (this.showModal == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
    closeModal() {
      this.showModal = false;

      if (this.showModal == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
  },
};
</script>

<style>
</style>
