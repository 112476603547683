<template>
  <div class="image_wrapper" :class="classes">
    <img class="image" :src="src" :alt="alt" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/Image/image.scss";
</style>