<template>
  <div class="dropdown-group_wrapper">
    <DropdownList
      id="firstDD"
      label="Merk"
      value="Kies het merk"
      :optionsList="firstDropdownArray"
      @selectedOption="ddselectedMark"
      @toggledd="dd1Clicked"
      :forceClose="activateWatcherDD1"
    />
    <DropdownList
      v-show="currentMark !== 'Onbekend'"
      id="secondDD"
      label="Serie"
      value="Kies serie"
      :optionsList="secondDropdownArray"
      @selectedOption="ddselectedSeries"
      :ddClass="disableClass"
      @toggledd="dd2Clicked"
      :forceClose="activateWatcherDD2"
    />
  </div>
</template>

<script>
import DropdownList from "@/components/atoms/DropdownList/DropdownList.vue";

export default {
  emits: ["selectedMark", "selectedSeries"],
  components: {
    DropdownList,
  },
  props: {
    firstDropdownArray: {
      type: Array,
      required: true,
    },
    secondDropdownArray: {
      type: Array,
      required: true,
    },
    disableClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      activateWatcherDD1: true,
      activateWatcherDD2: true,
      currentMark: null,
    };
  },
  methods: {
    ddselectedMark(mark) { 
      this.currentMark = mark;
      this.$emit("selectedMark", mark);
      if(mark === 'Onbekend'){
         this.ddselectedSeries('Onbekend');
      } 
    },
    ddselectedSeries(model) {
      this.$emit("selectedSeries", model);
    },
    dd1Clicked() {
      this.activateWatcherDD2 = !this.activateWatcherDD2;
    },
    dd2Clicked() {
      this.activateWatcherDD1 = !this.activateWatcherDD1;
    },
  },
};
</script>