<template>
  <div class="checkbox_wrapper">
    <label v-for="(option, i) of options" :key="i" class="checkbox_item">
      <span
        v-if="showLabel"
        class="checkbox_span"
        :for="option"
        :class="{ hidden: hiddenLabel }"
        >{{ option }}</span
      >
      <input
        class="checkbox_input"
        :id="option"
        v-model="checked"
        type="checkbox"
        :value="option"
        @change="onChange"
        :name="checkboxName"
      />
      <span class="checkbox_checkmark" />
    </label>
  </div>
</template>

<script>
export default {
  emits: ["checked"],
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    hiddenLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkboxName: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    onChange() {
      this.$emit("checked", this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/CheckBox/checkBox.scss";
</style>