<template>
  <div class="download-dropdown_wrapper">
    <img
    @click="toggle()"
      class="download-dropdown_head"
      :src="downloadIcon"
      alt="download icon"
    />
    <div class="download-dropdown_body-wrapper">
      <div class="download-dropdown_body" v-if="showDDList">
        <ul class="download-dropdown_list">
            <li class="download-dropdown_li">PDF bestand</li>
            <li class="download-dropdown_li">CSV bestand</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "@/assets/icons/download-icon.svg";
export default {
  data: () => ({
    downloadIcon: DownloadIcon,
    showDDList: false
  }),
    methods: {
    toggle() {
      this.showDDList = !this.showDDList;
    },
  },
};
</script>

 <style lang="scss" scoped>
@import "@/components/molecules/DownloadDropdown/downloadDropdown.scss";
</style>