<template>
  <div class="password-input_wrapper" :class="classes">
    <label class="password-input_label" :for="name">{{ label }}</label>
    <div
      class="password-input_input-container"
      :class="{ focus: focus, error: error, success: success }"
    >
      <input
        class="password-input"
        :type="type"
        :class="{ disabled: disabled }"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        v-model="inputField"
        @input="onchange"
      />
      <img
        class="password-input_icon"
        :src="seePassword ? crossedEyeIcon : eyeIcon"
        alt="see password"
        @click="togglePassword()"
      />
    </div>
  </div>
</template>

<script>
import PasswordIcon from "@/assets/icons/password-sprite.svg";

export default {
  emits: ["input"],
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: [Number, String],
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      type: "password",
      eyeIcon: PasswordIcon + "#eye",
      crossedEyeIcon: PasswordIcon + "#crossed-eye",
      seePassword: false,
      inputField: "",
    };
  },
  methods: {
    togglePassword() {
      this.seePassword = !this.seePassword;

      if (this.seePassword) {
        this.type = "text";
      } else if (!this.seePassword) {
        this.type = "password";
      }
    },
    onchange() {
      this.$emit("input", this.inputField);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/PasswordInput/passwordInput.scss";
</style>
