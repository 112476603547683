<template>
  <div class="accordion_wrapper">
    <div class="accordion_head">
      <h3 class="accordion_title">{{ title }}</h3>
      <div class="accordion_toggle-button" @click="toggleBody">
        <img
          class="accordion_icon"
          :src="accordionIcon"
          alt="open-close icon"
        />
      </div>
    </div>
    <div class="accordion_body" v-show="showBody">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import accordionSprites from "@/assets/icons/accordion-sprite.svg";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    white: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      accordionIcon: this.white ? accordionSprites + '#plus-white' : accordionSprites + '#plus',
      showBody: false,
    };
  },
  methods: {
    toggleBody() {
        this.showBody = !this.showBody

        if(!this.showBody) {
            this.accordionIcon = this.white ? accordionSprites + '#plus-white' : accordionSprites + '#plus'
        } else if(this.showBody) {
            this.accordionIcon = this.white ? accordionSprites + '#minus-white' : accordionSprites + '#minus'
        }
    },
  },
};
</script>

<style lang="scss">
@import "@/components/molecules/Accordion/accordion.scss";
</style>