<template>
  <div class="footer-nav_wrapper">
    <div class="footer-nav_inner-wrapper">
      <div class="footer-nav_nav-wrapper">
        <div class="footer-nav_col">
          <h3 class="footer-nav_col-title">Over ons</h3>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Over TU"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/over-ons.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Assortiment"
              variations="plain-button--nav"
              link="https://www.technischeunie.nl/catalogi-overview/"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Merken"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/merken-overzicht.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="TU nieuws"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/nieuws.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Vacatures"
              variations="plain-button--nav"
              link="https://www.werkenbijtechnischeunie.nl/vacatures"
            />
          </div>
        </div>
        <div class="footer-nav_col">
          <h3 class="footer-nav_col-title">Thema’s</h3>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Duurzaamheid"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/duurzaamheid.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Industrie"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/industrie.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Sanitair"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/sanitair.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Smart"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/smart.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Verlichting"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/verlichting.html"
            />
          </div>
        </div>
        <div class="footer-nav_col">
          <h3 class="footer-nav_col-title">Diensten</h3>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Onze diensten"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/diensten.html"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Voordelen klanten"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/voordelen.html"
            />
          </div>
        </div>
        <div class="footer-nav_col">
          <h3 class="footer-nav_col-title">Klantenservice</h3>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Contact"
              variations="plain-button--nav"
              link="https://www.technischeunie.nl/vestigingen/"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Vestigingen"
              variations="plain-button--nav"
              link="https://www.technischeunie.nl/vestigingen/"
            />
          </div>
          <div class="footer-nav_link-wrapper">
            <PlainButton
              text="Meestgestelde vragen"
              variations="plain-button--nav"
              link="https://www.c.technischeunie.nl/klantenservice.html"
            />
          </div>
        </div>
      </div>
      <div class="footer-nav_nav-accordion-wrapper">
        <Accordion
          title="Over ons"
          class="footer-nav_accordion-list"
          :white="true"
        >
          <UnorderedList classes="list--no-bullets">
            <li>
              <PlainButton
                text="Over TU"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/over-ons.html"
              />
            </li>
            <li>
              <PlainButton
                text="Assortiment"
                variations="plain-button--nav"
                link="https://www.technischeunie.nl/catalogi-overview/"
              />
            </li>
            <li>
              <PlainButton
                text="Merken"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/merken-overzicht.html"
              />
            </li>
            <li>
              <PlainButton
                text="TU nieuws"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/nieuws.html"
              />
            </li>
            <li>
              <PlainButton
                text="Vacatures"
                variations="plain-button--nav"
                link="https://www.werkenbijtechnischeunie.nl/vacatures"
              />
            </li>
          </UnorderedList>
        </Accordion>
        <Accordion
          title="Thema's"
          class="footer-nav_accordion-list"
          :white="true"
        >
          <UnorderedList classes="list--no-bullets">
            <li>
              <PlainButton
                text="Duurzaamheid"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/duurzaamheid.html"
              />
            </li>
            <li>
              <PlainButton
                text="Industrie"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/industrie.html"
              />
            </li>
            <li>
              <PlainButton
                text="Sanitair"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/sanitair.html"
              />
            </li>
            <li>
              <PlainButton
                text="Smart"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/smart.html"
              />
            </li>
            <li>
              <PlainButton
                text="Verlichting"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/verlichting.html"
              />
            </li>
          </UnorderedList>
        </Accordion>
        <Accordion
          title="Diensten"
          class="footer-nav_accordion-list"
          :white="true"
        >
          <UnorderedList classes="list--no-bullets">
            <li>
              <PlainButton
                text="Onze diensten"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/diensten.html"
              />
            </li>
            <li>
              <PlainButton
                text="Voordelen klanten"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/voordelen.html"
              />
            </li>
          </UnorderedList>
        </Accordion>
        <Accordion
          title="Klantenservice"
          class="footer-nav_accordion-list"
          :white="true"
        >
          <UnorderedList classes="list--no-bullets">
            <li>
              <PlainButton
                text="Contact"
                variations="plain-button--nav"
                link="https://www.technischeunie.nl/vestigingen/"
              />
            </li>
            <li>
              <PlainButton
                text="Vestigingen"
                variations="plain-button--nav"
                link="https://www.technischeunie.nl/vestigingen/"
              />
            </li>
            <li>
              <PlainButton
                text="Meestgestelde vragen"
                variations="plain-button--nav"
                link="https://www.c.technischeunie.nl/klantenservice.html"
              />
            </li>
          </UnorderedList>
        </Accordion>
      </div>
      <div class="footer-nav_logos-wrapper">
        <img class="footer-nav_logo" :src="logo" alt="logo" />
        <img class="footer-nav_second-icon" :src="secondIcon" alt="logo 2" />
      </div>
      <div class="footer-nav_back-to-top-wrapper">
        <BackToTop />
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from "@/components/atoms/PlainButton/PlainButton.vue";
import Accordion from "@/components/molecules/Accordion/Accordion.vue";
import UnorderedList from "@/components/atoms/UnorderedList/UnorderedList.vue";
import BackToTop from "@/components/atoms/BackToTop/BackToTop.vue";

import Logo from "@/assets/icons/logo-white.svg";
import SecondIcon from "@/assets/icons/logo-mogelijk.svg";

export default {
  components: {
    PlainButton,
    Accordion,
    UnorderedList,
    BackToTop,
  },
  data() {
    return {
      logo: Logo,
      secondIcon: SecondIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/FooterNav/footerNav.scss";
</style>