<template>
  <div
    class="charging-unit-vertical-card_outer-wrapper"
    :class="[showBorder, lastCard, firstCard]"
  >
    <div class="charging-unit-vertical-card_border"></div>
    <label class="charging-unit-vertical-card_wrapper" :for="cardOptions">
      <RadioButton
        class="charging-unit-vertical-card_radio-button"
        :radioOptions="[cardOptions]"
        labelPosition="radio-button--label-hidden"
        :radioName="checkerName"
        @checkedRadio="checkedCard"
      />
      <Image
        class="charging-unit-vertical-card_image image--vertical-card"
        :src="cardImage != '' ? cardImage : defaultImage"
        alt="cardImageAlt"
      />
      <p class="charging-unit-vertical-card_title">
        {{ title }}
      </p>
      <p class="charging-unit-vertical-card_subtitle">
        {{ subtitle }}
      </p>
      <p class="charging-unit-vertical-card_item-details">
        TU Artikelnummer: {{ articleNumber }}
      </p>
      <p
        class="
          charging-unit-vertical-card_item-details
          charging-unit-vertical-card_item-details--second
        "
      >
        Artikelnr. leverancier: {{ suppliercode }}
      </p>
      <br>
        <p class="charging-unit-vertical-card_price" v-if="price != 'NoApiCall'">
            € {{ brutoPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR",
            })
          }} Bruto
        </p>
        <p class="charging-unit-vertical-card_price" v-if="price != 'NoApiCall'">
          <b>€ {{ price.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR"
            })
          }}  Netto</b>
        </p>
      
      <div
        class="charging-unit-vertical-card_availability-wrapper"
        v-if="price != 'NoApiCall'"
      >
        <div class="charging-unit-vertical-card_availability-inner-wrapper">
          <Image
            class="
              charging-unit-vertical-card_check-icon
              image--vertical-card-check-icon
            "
            :src="quantityIcon"
            alt="icon"
          />
          <span :class="quantityClass">Op voorraad: {{ loadQuantity() }}</span>
        </div>
      </div>
    </label>
    <div class="charging-unit-vertical-card_table">
      <div class="charging-unit-vertical-card_table-item">Kleur</div>
      <div class="charging-unit-vertical-card_table-item">{{ color }}</div>
      <div class="charging-unit-vertical-card_table-item">Aansluiting uitgaand</div>
      <div class="charging-unit-vertical-card_table-item">{{ plug }}</div>
      <div class="charging-unit-vertical-card_table-item">Soort toegangscontrole</div>
      <div class="charging-unit-vertical-card_table-item">{{ accessControl }}</div>
      <div class="charging-unit-vertical-card_table-item">Load balancing</div>
      <div class="charging-unit-vertical-card_table-item">{{ loadBalancing }}</div>
      <div class="charging-unit-vertical-card_table-item">Incl. backoffice</div>
      <div class="charging-unit-vertical-card_table-item">{{ includingBackoffice }}</div>
      <div class="charging-unit-vertical-card_table-item">Bediening oplaadpunt</div>
      <div class="charging-unit-vertical-card_table-item">{{ manageType }}</div>
      <div class="charging-unit-vertical-card_table-item">Nominaal aansluitvermogen</div>
      <div class="charging-unit-vertical-card_table-item">{{ nominalConnectionValue / 1 + ' KWH' }}</div>
      <div class="charging-unit-vertical-card_table-item">Verrekening via back-office</div>
      <div class="charging-unit-vertical-card_table-item">{{ includingSimBackoffice }}</div>
      <div class="charging-unit-vertical-card_table-item">Met energiemeter</div>
      <div class="charging-unit-vertical-card_table-item">{{ withEnergyMeter }}</div>
      <div class="charging-unit-vertical-card_table-item">Communicatie interface</div>
      <div class="charging-unit-vertical-card_table-item">{{ communicationInterface }}</div>
    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import RadioButton from "@/components/atoms/RadioButton/RadioButton.vue";
import DefaultImage from "@/assets/images/default-image.png";
import WarningIcon from "@/assets/icons/warning.svg";
import FalseIcon from "@/assets/icons/falseIcon.svg";
import StepsSprite from "@/assets/icons/steps-sprite.svg";

export default {
  emits: ["checkedproductcard"],
  components: {
    Image,
    RadioButton,
  },
  props: {
    cardOptions: {
      type: String,
      required: false,
    },
    checkerName: {
      type: String,
      required: false,
    },
    cardImage: {
      type: String,
      required: false,
      default: DefaultImage,
    },
    cardImageAlt: {
      type: String,
      required: false,
      default: "card image",
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    articleNumber: {
      type: String,
      required: true,
    },
    suppliercode: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    productQuantity: {
      type: String,
      required: true,
    },
    deliveryTimeDelay: {
      type: String,
      required: true,
      default: "0",
    },
    lastCard: {
      type: String,
      required: false,
    },
    firstCard: {
      type: String,
      required: false,
    },
    showBorder: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    typeConnectionBackend:{
      type: String,
      required: false
    },
    fuseRatingConnection:{
      type: String,
      required: false
    },
    plug: {
      type: String,
      required: false
    },
    accessControl: {
      type: String,
      required: false
    },
    loadBalancing: {
      type: String,
      required: false
    },
    dynamicLoadManagement: {
      type: String,
      required: false
    },
    model: {
      type: String,
      required: false
    },
    includingBackoffice: {
      type: String,
      required: false
    },
    manageType: {
      type: String,
      required: false
    },
    nominalConnectionValue: {
      type: String,
      required: false
    },
    includingSimBackoffice: {
      type: String,
      required: false
    },
    withEnergyMeter: {
      type: String,
      required: false
    },
     communicationInterface: {
      type: String,
      required: false
    },
    brutoPrice: {
      type: Number,
      required: false
    }


  },
  data: () => ({
    quantityIcon: StepsSprite + "#step-5-green",
    quantityClass: "charging-unit-vertical-card_availability--green",
    deliveryTime: "Morgen bezorgd",
    falseIcon: FalseIcon,
    trueIcon: StepsSprite + "#step-5-green",
    defaultImage: DefaultImage
  }),
  methods: {
    loadQuantity() {
      if (this.productQuantity > 99) {
        // add + for quantity over 99
        this.quantityIcon = StepsSprite + "#step-5-green";
        this.quantityClass = "charging-unit-vertical-card_availability--green";
        this.deliveryTime = "Morgen bezorgd";
        return "99+";
      } else if (this.productQuantity <= 99 && this.productQuantity > 0) {
        // show quantity number
        this.quantityIcon = StepsSprite + "#step-5-green";
        this.quantityClass = "charging-unit-vertical-card_availability--green";
        this.deliveryTime = "Morgen bezorgd";
        return this.productQuantity;
      } else if (this.productQuantity <= 0) {
        // show text that product is not available and show delivery time in weeks
        this.quantityIcon = WarningIcon;
        this.quantityClass = "charging-unit-vertical-card_availability--yellow";
        this.deliveryTime = this.deliveryTimeDelay;
        return "Niet";
      }
    },
    checkedCard(checked) {
      this.$emit(
        "checkedproductcard",
        checked,
        this.cardOptions,
        this.checkerName,
        this.cardImage,
        this.cardImageAlt,
        this.title,
        this.subtitle,
        this.articleNumber,
        this.suppliercode,
        this.productQuantity,
        this.deliveryTimeDelay,
        this.price,
        this.brutoPrice,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/ChargingUnitVerticalCard/chargingUnitVerticalCard.scss";
</style>
