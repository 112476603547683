<template>
  <img class="more-info_image" :src="defaultImage" alt="image" />
  <div class="more-info_text-wrapper">
    <h2 class="more-info_title">{{ title }}</h2>
    <div class="more-info_description-wrapper">
      <slot>
        <!-- insert paragraphs here -->
      </slot>
    </div>
  </div>
</template>

<script>
import DescriptionImage from "@/assets/images/description-image.jpeg";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    defaultImage: DescriptionImage,
  }),
};
</script>

<style lang="scss">
@import "@/components/molecules/MoreInformations/moreInformations.scss";
</style>