<template>
  <Header :username="username" />
  <Container>
    <h1>LAADPALEN KEUZEHULP</h1>
    <router-view />
  </Container>
  <FooterDynamic />
  <FooterNav />
  <Footnote />
</template>

<script>
import Header from "@/components/molecules/Header/Header.vue";
import FooterDynamic from "@/components/molecules/FooterDynamic/FooterDynamic.vue";
import FooterNav from "@/components/molecules/FooterNav/FooterNav.vue";
import Footnote from "@/components/molecules/Footnote/Footnote.vue";
import Container from "@/components/containers/Container/Container.vue";
import Stepper from "@/components/containers/Stepper/Stepper.vue";
import LoginPage from "@/components/organisms/LoginPage/LoginPage.vue";

import defaultCoverImage from "@/assets/images/default-cover.png";
import { AUTO_LOGIN_ACTION } from "@/store/storeconstants";
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      autoLogout: (state) => state.auth.autoLogout,
    }),
  },
  watch: {
    autoLogout(currValue, oldValue) {
      if (currValue && currValue != oldValue) {
        this.$router.replace("/login");
      }
    },
  },
  components: {
    Container,
    LoginPage,
    Stepper,
    Header,
    FooterDynamic,
    FooterNav,
    Footnote,
  },
  data() {
    return {
      bannerImage: defaultCoverImage,
      username: "",
      userNotLoggedIn: true,
    };
  },
  created() {
    this.$store.dispatch(`auth/${AUTO_LOGIN_ACTION}`);
  },
};
</script>

<style lang="scss">
@import "@/scss/general";
@import "@/scss/typography";
</style>
