<template>
  <button
    @click="$emit('clickedBtn')"
    type="button"
    class="button"
    :class="buttonVariation"
    :disabled="disableButton"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonVariation: {
      type: String,
      required: false,
    },
    disableButton: {
      type: Boolean,
      required: false,
      default: false
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/Button/button.scss";
</style>
