import { addToCartURL, URL } from '@/store/consts'
import {
  ADD_TO_CART,
  CHARGING_TIME, PRODUCT_ACCESSORIES, PRODUCT_CARD_LIST, SET_CHARGING_TIME, SET_PRODUCT_ACCESSORIES, SET_PRODUCT_CARD_LIST, SET_VEHICLE_BRANDS_LIST, SET_VEHICLE_MODEL_LIST, VEHICLE_BRANDS_LIST, VEHICLE_MODEL_LIST
} from '@/store/storeconstants'
import Axios from 'axios'

function escapeXml (unsafe) {
  return unsafe.replace(/[<>&'"]/g, function (c) {
    switch (c) {
      case '<': return '&lt;'
      case '>': return '&gt;'
      case '&': return '&amp;'
      case '\'': return '&apos;'
      case '"': return '&quot;'
    }
  })
}

export default {
  async [PRODUCT_ACCESSORIES] (context, payload) {
    const articleNumber = payload.articleNumber
    const token = payload.token
    let response = ''

    try {
      await Axios.post(
                `${URL}/eMobility/chargingPointAccessories/${articleNumber}`,
                null,
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                }
      ).then((resp) => {
        response = resp
      })
    } catch (error) {
      console.log(error)
    }

    const cablesArray = []
    const additionalAccessoriesArray = []

    response.data.forEach(item => {
      if (item.cableLength == 'None') {
        additionalAccessoriesArray.push(item)
      } else {
        cablesArray.push(item)
      }
    })

    context.commit(SET_PRODUCT_ACCESSORIES, { cablesArray, additionalAccessoriesArray })
  },

  async [PRODUCT_CARD_LIST] (context, payload) {
    const postData = {
      automaticInvoicing: payload.automaticInvoicing,
      wallMount: payload.wallMount,
      fixedCable: payload.fixedCable,
      monoPhase: payload.monoPhase,
      cableTypeOne: payload.cableTypeOne,
      cableTypeTwo: payload.cableTypeTwo,
      loadBalancing: payload.loadBalancing,
      chargingUnits: payload.chargingUnits
    }

    const token = payload.token
    let response = ''

    try {
      await Axios.post(
                `${URL}/eMobility/chargingPoint`,
                postData, {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                }
      ).then((resp) => {
        response = resp
      })
    } catch (error) {
      console.log(error)
    }

    const products = response.data.filter(function (product) {
      let passed = false
      if (product.numberOfUnits !== '0') {
        passed = true
      }
      if ([
        '6521609', '7244233', '6521574', '6521588', '6521560', '7596320', '7596334',
        '3274492', '7064873', '7064845', '7064852', '7064803', '6357264', '3274485'
      ].includes(product.articleNumber)) {
        passed = false
      }
      return passed
    })

    context.commit(SET_PRODUCT_CARD_LIST, products)
  },

  async [VEHICLE_BRANDS_LIST] (context) {
    let response = ''

    try {
      await Axios.get(
                `${URL}/eVehicle/`
      ).then((resp) => {
        response = resp
      })
    } catch (error) {
      console.log(error)
    }

    context.commit(SET_VEHICLE_BRANDS_LIST, response)
  },

  async [VEHICLE_MODEL_LIST] (context, payload) {
    let response = ''

    try {
      await Axios.get(
                `${URL}/eVehicle/` + payload.mark
      ).then((resp) => {
        response = resp
      })
    } catch (error) {
      console.log(error)
    }

    context.commit(SET_VEHICLE_MODEL_LIST, response)
  },

  async [CHARGING_TIME] (context, payload) {
    const postData = {
      model: payload.model,
      brand: payload.brand,
      version: payload.version
    }

    let response = ''

    try {
      await Axios.post(
                `${URL}/eVehicle/carDetails`,
                postData
      ).then((resp) => {
        response = resp
      })
    } catch (error) {
      console.log(error)
    }

    context.commit(SET_CHARGING_TIME, response)
  },

  async [ADD_TO_CART] (context, payload) {
    const user = payload.username
    const product = payload.selectedPruduct
    const cables = payload.selectedCables
    const accessories = payload.selectedAccessories

    let xmlBody = `
<list>
   <SENDER>${user}</SENDER>
   <ARTICLE>
    <EAN/>
    <TU_ARTICLE>${product.articleNumber}</TU_ARTICLE>
    <TYPE>${product.title.substring(0, 50) && escapeXml(product.title.substring(0, 50))}</TYPE>
    <MANUFACTURER_PID>${product.suppliercode && escapeXml(product.suppliercode)}</MANUFACTURER_PID>
    <ORDER_QUANTITY>1</ORDER_QUANTITY>
    <DESCRIPTION>${product.subtitle && escapeXml(product.subtitle)}</DESCRIPTION>
    <PRICEUNIT>${product.price}</PRICEUNIT>
    <UNITPRICE>${product.price}</UNITPRICE>
   </ARTICLE>

${accessories && accessories.map(elem => `   <ARTICLE>
    <EAN/>
    <TU_ARTICLE>${elem.articleNumber}</TU_ARTICLE>
    <TYPE>${elem.title.substring(0, 50) && escapeXml(elem.title.substring(0, 50))}</TYPE>
    <MANUFACTURER_PID>${elem.suppliercode && escapeXml(elem.suppliercode)}</MANUFACTURER_PID>
    <ORDER_QUANTITY>1</ORDER_QUANTITY>
    <DESCRIPTION>${elem.subtitle && escapeXml(elem.subtitle)}</DESCRIPTION>
    <PRICEUNIT>${elem.price}</PRICEUNIT>
    <UNITPRICE>${elem.price}</UNITPRICE>
   </ARTICLE>`)}
`

    cables.forEach((cable) => {
      xmlBody += `
    ${cable && cable.articleNumber !== undefined
? `   <ARTICLE>
    <EAN/>
    <TU_ARTICLE>${cable.articleNumber}</TU_ARTICLE>
    <TYPE>${cable.title.substring(0, 50) && escapeXml(cable.title.substring(0, 50))}</TYPE>
    <MANUFACTURER_PID>${cable.suppliercode && escapeXml(cable.suppliercode)}</MANUFACTURER_PID>
    <ORDER_QUANTITY>1</ORDER_QUANTITY>
    <DESCRIPTION>${cable.subtitle && escapeXml(cable.subtitle)}</DESCRIPTION>
    <PRICEUNIT>${cable.price}</PRICEUNIT>
    <UNITPRICE>${cable.price}</UNITPRICE>
    </ARTICLE>`
: ''}
    `
    })

    xmlBody += '</list>'

    try {
      const form = document.createElement('form')
      form.action = addToCartURL
      form.method = 'post'

      const xmlField = document.createElement('input')
      xmlField.name = 'xml'
      xmlField.value = xmlBody

      form.appendChild(xmlField)
      document.body.appendChild(form)
      form.submit()
    } catch (error) {
      console.log(error)
    }
  }
}
