<template>
  <div class="input-field_wrapper" :class="classes">
    <label class="input-field_label" :for="name">{{ label }}</label>
    <div
      class="input-field_input-container"
      :class="{ focus: focus, error: error, success: success }"
    >
      <input
        class="input-field"
        :type="type"
        :class="{ disabled: disabled }"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        v-model="inputField"
        @input="onchange"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["input"],
  name: "InputField",
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: [Number, String],
      required: false,
    },
    classes: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputField: "",
    };
  },
  methods: {
    onchange() {
      this.$emit("input", this.inputField);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/atoms/InputField/inputField.scss";
</style>
