<template>
  <div class="footnote_wrapper">
    <div class="footnote_inner-wrapper">
      <div class="footnote_links-wrapper">
        <p class="footnote_copyright">© Technische Unie</p>
        <PlainButton
          text="Disclaimer"
          variations="plain-button--dark-small"
          link="https://www.technischeunie.nl/disclaimer/"
        />
        <PlainButton
          text="Privacy statement"
          variations="plain-button--dark-small"
          link="https://www.technischeunie.nl/categorie/privacy"
        />
        <PlainButton
          text="Algemene voorwaarden"
          variations="plain-button--dark-small"
          link="https://www.technischeunie.nl/categorie/legal"
        />
        <PlainButton
          text="Compliance"
          variations="plain-button--dark-small"
          link="https://www.technischeunie.nl/categorie/compliance"
        />
      </div>

      <div class="footnote_icons-wrapper">
        <a
          href="https://www.facebook.com/TechnischeUnie/"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="facebook" alt="facebook icon" />
        </a>
        <a
          href="https://wa.me/31651254632"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="whatsapp" alt="whatsapp icon" />
        </a>
        <a
          href="https://www.instagram.com/technischeunie/"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="instagram" alt="instagram icon" />
        </a>
        <a
          href="https://www.linkedin.com/company/technische-unie/"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="linkedin" alt="linkedin icon" />
        </a>
        <a
          href="https://twitter.com/technische_unie"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="twitter" alt="twitter icon" />
        </a>
        <a
          href="https://www.youtube.com/user/tukanaal"
          target="_blank"
          class="footnote_icon-link"
        >
          <img class="footnote_icon" :src="youtube" alt="youtube icon" />
        </a>
      </div>
      <div class="footnote_back-to-top-wrapper">
        <BackToTop />
      </div>
    </div>
  </div>
</template>

<script>
import PlainButton from "@/components/atoms/PlainButton/PlainButton.vue";
import BackToTop from "@/components/atoms/BackToTop/BackToTop.vue";

import SocialMediaIcon from "@/assets/icons/social-media-sprite.svg";

export default {
  components: {
    PlainButton,
    BackToTop,
  },
  data() {
    return {
      facebook: SocialMediaIcon + "#facebook",
      whatsapp: SocialMediaIcon + "#whatsapp",
      instagram: SocialMediaIcon + "#instagram",
      linkedin: SocialMediaIcon + "#linkedin",
      twitter: SocialMediaIcon + "#twitter",
      youtube: SocialMediaIcon + "#youtube",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/Footnote/footnote.scss";
</style>