<template>
  <div class="selection-results_wrapper">
    <h2 class="title--green">{{ title }}</h2>
    <h3 class="selection-results_description">
      We hebben de volgende laadunits voor u geselecteerd:
    </h3>
    <div class="selection-results_list-wrapper">
      <div class="selection-results_list-item">
        <div class="selection-results_list-item-title-wrapper">
          <Image
            class="selection-results_icon image--icon"
            :src="step1Icon"
            alt="image"
          />
          <h3 class="selection-results_list-item-title title--green">
            Situatie
          </h3>
        </div>
        <p class="selection-results_list-item-description">
          {{ selectedMark === 'Onbekend' ? '' : selectedMark }} {{ selectedSeries === 'Onbekend' ? 'Voertuig: Onbekend' : selectedSeries}}
        </p>
        <p class="selection-results_list-item-description">
          {{ selectedUnitsNumber === '1' ? '1 laadunit' : '1 laadunit met 2 laadpunten' }} met {{ selectedCostOption === 'Anders dan GEEN' ? '(Zakelijke) kosten wel verrekenen' :'(Zakelijke) kosten niet verrekenen'  }}
          
        </p>
      </div>
      <div class="selection-results_list-item">
        <div class="selection-results_list-item-title-wrapper">
          <Image
            class="selection-results_icon image--icon"
            :src="step2Icon"
            alt="image"
          />
          <h3 class="selection-results_list-item-title title--green">
            Plaatsing
          </h3>
        </div>
        <p class="selection-results_list-item-description">
          Montage: {{ selectedChargerPosition }}
          <br>
         {{ selectedChargingCable }}
        </p>
      </div>
      <div class="selection-results_list-item">
        <div class="selection-results_list-item-title-wrapper">
          <Image
            class="selection-results_icon image--icon"
            :src="step3Icon"
            alt="image"
          />
          <h3 class="selection-results_list-item-title title--green">
            Aansluiting
          </h3>
        </div>
        <p class="selection-results_list-item-description">
          {{ selectedPhases }} meterkast met {{ selectedAmpere }} ampere
        </p>
        <p class="selection-results_list-item-description">
          {{ selectedLoadbalancing === 'true' ? 'Statisch' : 'Dynamisch' }} loadbalancing
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "@/components/atoms/Image/Image.vue";
import StepsSprite from "@/assets/icons/steps-sprite.svg";

export default {
  components: {
    Image,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    selectedMark: {
      type: String,
      required: true,
    },
    selectedSeries: {
      type: String,
      required: true,
    },
    selectedUnitsNumber: {
      type: String,
      required: true,
    },
    selectedCostOption: {
      type: String,
      required: true,
    },
    selectedChargerPosition: {
      type: String,
      required: true,
    },
    selectedChargingCable: {
      type: String,
      required: true,
    },
    selectedPhases: {
      type: String,
      required: true,
    },
    selectedAmpere: {
      type: String,
      required: true,
    },
    selectedLoadbalancing: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    step1Icon: StepsSprite + "#step-1-green",
    step2Icon: StepsSprite + "#step-2-green",
    step3Icon: StepsSprite + "#step-3-green",
  }),
};
</script>

<style lang="scss" scoped>
@import "@/components/molecules/SelectionResults/selectionResults.scss";
</style>