<template>
<div @click="backToTop" class="back-to-top_wrapper">
    <img class="back-to-top_icon" :src="upArrow" alt="up">
</div>

</template>

<script>
import UpArrow from "@/assets/icons/up-arrow.svg";

export default {
data: () => ({
    upArrow: UpArrow
}),
methods: {
    backToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
}
</script>

<style lang="scss" scoped>
@import "@/components/atoms/BackToTop/backToTop.scss";
</style>
