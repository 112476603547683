<template>
  <h2 class="title--green">Situatie</h2>
  <div class="step-1_car-picker-wrapper">
    <div class="step-1_dropdowns-wrapper">
      <h3 class="step-1_subtitle">Kies uw auto</h3>
      <DropdownGroup
        :firstDropdownArray="vehicleBrandsList"
        :secondDropdownArray="
          getModelList.length > 0
            ? getModelList.map((el) => el.modelVersion)
            : ['Kies eerst het merk']
        "
        @selectedMark="getSeriesArray"
        @selectedSeries="getModelVersion"
        :disableClass="secondDDdisabled"
      />
    </div>
  </div>

  <RadioButtonCardGroups
    title="Zijn er 1 of meer laadunits nodig?"
    :optionsArray="chargingUnits"
    @checkedRadioOption="checkedNoLaadunits"
    :warningMessage="multipleLaadunitsMessage"
  />
  <RadioButtonCardGroups
    title="Worden de kosten nu of in de toekomst (zakelijk) verrekend?"
    description="Sommige laadunits kunnen de laadkosten opslaan en naar derden (bijv. de werkgever of bezoekers) versturen. "
    :optionsArray="businessCosts"
    @checkedRadioOption="checkedCostOption"
    @clickedlink="toggleModal"
  />
  <Button
    @clickedBtn="submit"
    buttonText="Naar plaatsing"
    buttonVariation="button--primary"
    :disableButton="disabledButton"
    class="button-situatie"
  />
  <Modal
    :modalPaddingNone="true"
    :show="showModal"
    @close="closeModal"
    @clickbackgdrop="closeModal"
    :closeButton="showModalCloseButton"
  >
    <MoreInformations title="Verrekening met uw laadpaal">
      <p>
       Mag u de gemaakte laadkosten verrekenen met uw werkgever?<br>
De laadpaal moet beschikken over een RFID reader, MID meter (geijkte kWh-meter) en de mogelijkheid om de laadpaal te koppelen met een CPO (charge Point Operator). Hiervoor dient u een abonnement af te sluiten per laadunit.

      </p>
      <p>
       Een backofficeprovider zorgt ervoor dat de laadkosten verrekend worden. Daarnaast biedt dit meteen de mogelijkheid om anderen ook gebruik te laten maken van de laadunit.  
      </p>
    </MoreInformations>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import DropdownGroup from "@/components/molecules/DropdownGroup/DropdownGroup.vue";
import RadioButtonCardGroups from "@/components/organisms/RadioButtonCardGroups/RadioButtonCardGroups.vue";
import Button from "@/components/atoms/Button/Button.vue";
import Image from "@/components/atoms/Image/Image.vue";
import Modal from "@/components/containers/Modal/Modal.vue";
import MoreInformations from "@/components/molecules/MoreInformations/MoreInformations.vue";

import ChargingUnit from "@/assets/icons/chargingunit.svg";
import ChargingUnitTwo from "@/assets/icons/charging_units_double_chord.svg";
import ChargingUnits from "@/assets/icons/chargingunits.svg";
import PriceBefore from "@/assets/icons/pricebefore.svg";
import PriceAfter from "@/assets/icons/priceafter.svg";
import {
  GET_VEHICLE_BRANDS_LIST,
  GET_VEHICLE_MODEL_LIST,
  SET_FIRST_STEP_CHOICES,
  SET_MODEL_BRAND_VERSION,
  VEHICLE_BRANDS_LIST,
  VEHICLE_MODEL_LIST,
} from "@/store/storeconstants";

export default {
  emits: ["submitstep1"],
  components: {
    DropdownGroup,
    RadioButtonCardGroups,
    Button,
    Image,
    Modal,
    MoreInformations,
  },
  data: () => ({
    step: 1,
    chargingUnits: [
      {
        image: ChargingUnit,
        imageAlt: "laadunit",
        option: "1 laadunit",
        radioName: "chargingUnits",
        radioValue: "1"
      },
      {
        image: ChargingUnitTwo,
        imageAlt: "laadunit",
        option: "1 laadunit met 2 laadpunten",
        radioName: "chargingUnits",
        radioValue: "2"
      },
      {
        image: ChargingUnits,
        imageAlt: "laadunit",
        option: ">1 laadunit",
        radioName: "chargingUnits",
      },
    ],
    businessCosts: [
      {
        image: PriceBefore,
        imageAlt: "img",
        option: "(Zakelijke) kosten wel verrekenen",
        radioName: "businessCosts",
        radioValue: "Anders dan GEEN"
      },
      {
        image: PriceAfter,
        imageAlt: "img",
        option: "(Zakelijke) kosten niet verrekenen",
        radioName: "businessCosts",
        radioValue: "GEEN"
      },
    ],
    oneUnitImage: ChargingUnit,
    multipleUnitsImage: ChargingUnits,
    multipleLaadunitsMessage: "",
    seriesObject: {},
    secondDDdisabled: "disabled",
    selectedMark: "",
    selectedSeries: "",
    selectedLaadunitsNumber: "",
    selectedCostOption: "",
    disabledButton: true,
    showModal: false,
    showModalCloseButton: true,
  }),
  computed: {
    ...mapGetters("app", {
      vehicleBrandsList: GET_VEHICLE_BRANDS_LIST,
      getModelList: GET_VEHICLE_MODEL_LIST,
    }),
  },
  methods: {
    ...mapActions("app", {
      brandList: VEHICLE_BRANDS_LIST,
      modelList: VEHICLE_MODEL_LIST,
    }),
    ...mapMutations("app", {
      setFirstStepChoices: SET_FIRST_STEP_CHOICES,
      setModelBrandVersion: SET_MODEL_BRAND_VERSION,
    }),
    getSeriesArray(mark) {
      this.modelList({ mark: mark });
      this.selectedMark = mark;

      this.secondDDdisabled = "";

      if(this.getModelList.includes(this.selectedSeries)) {
        this.selectedSeries = "";
      }
      this.enableButton();
    },
    getModelVersion(series) {
      this.selectedSeries = series;

      for (let i in this.getModelList) {
        if (this.selectedSeries == this.getModelList[i].modelVersion) {
          this.setModelBrandVersion({
            model: this.getModelList[i].model,
            brand: this.getModelList[i].brand,
            version: this.getModelList[i].version,
          });
        }
      }

      this.enableButton();
    },
    checkedNoLaadunits(checked) {
      this.selectedLaadunitsNumber = checked;

      if (this.selectedLaadunitsNumber == ">1 laadunit") {
        this.multipleLaadunitsMessage =
          "De laadpalen keuzehulp geeft nog geen advies over het gebruik van meer dan 1 laadunit. Wilt u meer dan 1 laadunit plaatsen neem dan contact op met uw verkoopkantoor.";
      } else if(this.selectedLaadunitsNumber == "2"){
          this.multipleLaadunitsMessage =
          "Let op: enkel mogelijk in combinatie met 3-fase meterkast";
      } else {
        this.multipleLaadunitsMessage = "";
      }

      this.enableButton();
    },
    checkedCostOption(checked) {
      this.selectedCostOption = checked;
      this.enableButton();
    },
    enableButton() {
      if (
        this.selectedMark != "" &&
        this.selectedSeries != "" &&
        this.selectedLaadunitsNumber != "" &&
        this.selectedCostOption != ""
      ) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
      }
    },
    submit() {
      this.$emit("submitstep1");
      this.setFirstStepChoices({
        charging_units: this.selectedLaadunitsNumber,
        costs: this.selectedCostOption,
      });
      
    },
    toggleModal() {
      this.showModal = !this.showModal;

      if (this.showModal == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
    closeModal() {
      this.showModal = false;
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    },
  },
  mounted() {
    this.brandList();
  },
};
</script>

<style lang="scss">
@import "@/components/organisms/Step1/step1.scss";
</style>