<template>
  <div class="step-4_wrapper" ref="step4Wrapper">
    <SelectionResults
      title="Selectie"
      :selectedMark="selectedMark"
      :selectedSeries="selectedSeries"
      :selectedUnitsNumber="selectedUnitsNumber"
      :selectedCostOption="selectedCostOption"
      :selectedChargerPosition="selectedChargerPosition"
      :selectedChargingCable="selectedChargingCable"
      :selectedPhases="selectedPhases"
      :selectedAmpere="selectedAmpere"
      :selectedLoadbalancing="selectedLoadbalancing"
    />
    <div v-if="!selectedProduct">
      <h2 class="step-4_title title--green">Laadunits:</h2>
      <h3 class="selection-results_description" v-html="cardsTitle"></h3>
      <div class="selection-results_button-wrapper">
        <PlainButton
          text="Producten vergelijken"
          variations="plain-button--underline"
          :disabled="
            selectedCardsArray.length > 4 || selectedCardsArray.length <= 0
              ? 'disabled'
              : ''
          "
          link="javascript:void(0)"
          @clickedBtn="compareProducts"
          v-if="!selectedCardsForComparison"
        />

        <a
          v-if="selectedCardsForComparison"
          class="title--green step-4_edit-button"
          href="/ordering-tool"
        >
          Pas criteria aan
        </a>

        <DropdownList
          value="Voorraad aflopend"
          :isOval="true"
          :optionsList="['Prijs oplopend', 'Prijs aflopend', 'Voorraad oplopend' ,'Voorraad aflopend','A-Z', 'Z-A']"
          id="sortingList"
          @selectedOption="sortButton"
          v-if="!selectedCardsForComparison"
        />
      </div>
      <div class="step-4_loader-wrapper" v-if="isLoading">
        <Loader />
      </div>
      <div class="selection-results_arrows-wrapper" v-if="showNextPrevButtons">
        <div @click="scrollLeft()" class="selection-results_prev-arrow"></div>
        <div
          @click="scrollRight()"
          class="
            selection-results_arrow-icon-wrapper selection-results_next-arrow
          "
        ></div>
      </div>
      <div class="step-4_selection-cards-wrapper">
        <div
          class="selection-results_cards-wrapper"
          :class="
            selectedCardsForComparison
              ? 'selection-results_cards-wrapper--vertical'
              : 'selection-results_cards-wrapper--horizontal'
          "
          ref="productCardsWrapper"
        >
          <div v-for="(item, index) in selectedCardsArray" :key="item.title">
            <ChargingUnitVerticalCard
              v-if="selectedCardsForComparison"
              :cardImage="item.imageURL"
              cardImageAlt="image"
              :title="item.consumerFriendlyTitle"
              :subtitle="item.shortDescription"
              :articleNumber="item.articleNumber"
              :suppliercode="item.supplierCode"
              :price="item.price"
              :brutoPrice="item.brutoPrice"
              :productQuantity="item.numberOfUnits"
              checkerName="productCard"
              :cardOptions="item.articleNumber"
              :deliveryTimeDelay="item.deliveryTime"
              :color="item.color"
              :typeConnectionBackend="item.typeConnectionBackend"
              :fuseRatingConnection="item.fuseRatingConnection"
              :accessControl="item.accessControl"
              :dynamicLoadManagement="item.dynamicLoadManagement"
              :connectionCapacity="item.connectionCapacity"
              :plug="item.plug"
              :includingBackoffice="item.includingBackoffice"
              :loadBalancing="item.loadBalancing"
              :model="item.model"
              :manageType="item.manageType"
              :nominalConnectionValue="item.nominalConnectionValue"
              :includingSimBackoffice="item.includingSimBackoffice === 'J' ? 'Ja' : 'Nee'"
              :withEnergyMeter="item.withEnergyMeter === 'J' ? 'Ja' : 'Nee'"
              :communicationInterface="item.communicationInterface === 'J' ? 'Ja' : 'Nee'"
              :display="item.display"
              @checkedproductcard="finalProductSelected"
              :lastCard="
                selectedCardsArray.length - 1 == index ? 'last-card' : ''
              "
              :firstCard="index == 0 ? 'first-card' : ''"
              :showBorder="addBorder(item.articleNumber)"
            />
          </div>
          <div v-for="item in productCardsArray" :key="item.title">
            <HorizontalCardProduct
              v-if="!selectedCardsForComparison"
              :cardImage="item.imageURL"
              cardImageAlt="image"
              :title="item.consumerFriendlyTitle"
              :subtitle="item.shortDescription"
              :articleNumber="item.articleNumber"
              :suppliercode="item.supplierCode"
              :productQuantity="item.numberOfUnits"
              :deliveryTimeDelay="item.deliveryTime"
              checkerName="productCard"
              :cardOptions="item.articleNumber"
              :price="item.price"
              :brutoPrice="item.brutoPrice"
              :width="item.width"
              :height="item.height"
              :depth="item.depth"
              :color="item.color"
              :typeConnectionBackend="item.typeConnectionBackend"
              :fuseRatingConnection="item.fuseRatingConnection"
              :accessControl="item.accessControl"
              :connectionCapacity="item.connectionCapacity"
              :dynamicLoadManagement="item.dynamicLoadManagement"
              :plug="item.plug"
              :model="item.model"
              :includingBackoffice="item.includingBackoffice"
              :loadBalancing="item.loadBalancing"
              :manageType="item.manageType"
              :nominalConnectionValue="item.nominalConnectionValue"
              :includingSimBackoffice="item.includingSimBackoffice === 'J' ? 'Ja' : 'Nee'"
              :withEnergyMeter="item.withEnergyMeter === 'J' ? 'Ja' : 'Nee'"
              :communicationInterface="item.communicationInterface === 'J' ? 'Ja' : 'Nee'"
              :display="item.display"
              :solarPanelCompatible="item.solarPanelCompatible"
              :cableLength="item.cableLength"
              @checkedproductcard="selectedForComparison(checked, item)"
              @selectedProduct="directSelectedProduct"

            />
          </div>
        </div>
        <Button
          v-if="selectedCardsForComparison"
          @clickedBtn="confirmSelection"
          buttonText="Bevestig keuze"
          buttonVariation="button--primary button--confirm-selected"
          :disableButton="disableVerticalCardButton"
        />
      </div>
    </div>
    <div v-if="selectedProduct">
      <h2 class="step-4_title title--green">Geselecteerde laadunit:</h2>
      <HorizontalCard
        :radioButton="false"
        :cardImage="finalProductSelection.image"
        :cardImageAlt="finalProductSelection.imageAlt"
        :title="finalProductSelection.title"
        :subtitle="finalProductSelection.subtitle"
        :articleNumber="finalProductSelection.articleNumber"
        :suppliercode="finalProductSelection.suppliercode"
        :productQuantity="finalProductSelection.productQuantity"
        :deliveryTimeDelay="finalProductSelection.deliveryTimeDelay"
        :price="finalProductSelection.price"
        :brutoPrice="finalProductSelection.brutoPrice"
      />
      <div class="selection-results_button-wrapper">
        <PlainButton
          text="Terug naar alle laadunits"
          variations="plain-button--underline"
          link="javascript:void(0)"
          @clickedBtn="backToAllUnits"
        />
      </div>
      <div class="step-4_cables-wrapper" v-if="cablesArray.length > 0">
        <h2 class="step-4_title title--green">Bijbehorende producten</h2>
        <MessageCard
          messageType="warning"
          classes="message-card--warning"
          description="U kunt de geselecteerde bijbehorende producten uitgebreider bekijken en wijzigen in uw winkelmand."
        />
         <MessageCard
          v-if="finalProductSelection.plug === 'Kabel met stekker, type 2 (excl.)'"
          messageType="warning"
          classes="message-card--warning"
          description="Let op: u dient de kabel nog te selecteren uit de toebehoren."
        />
         <MessageCard
          v-if="finalProductSelection.loadBalancing === 'Dynamisch, met kWh meter (excl.)' || finalProductSelection.loadBalancing === 'Dynamisch, met meetspoelen (excl.)'"
          messageType="warning"
          classes="message-card--warning"
          description="Let op: uw installatie vereist extra losse componenten uit de toebehoren"
        />
        <p>Maak een keuze.</p>
        <div v-for="item in cablesArray" :key="item.articleNumber">
          <HorizontalCardCheckbox
            :cardImage="item.imageURL"
            cardImageAlt="image"
            :title="item.title"
            :subtitle="item.description"
            :articleNumber="item.articleNumber"
            :suppliercode="item.supplierCode"
            :productQuantity="item.numberOfUnits"
            :deliveryTimeDelay="item.deliveryTime"
            checkerName="cableCards"
            :cardOptions="item.articleNumber"
            :price="item.price"
            :brutoPrice="item.brutoPrice"
            @checkedcard="selectedCable(checked, item)"
          />
        </div>
      </div>
      <div
        class="step-4_additional-accessories-wrapper"
        v-if="additionalAccessoriesArray.length > 0"
      >
        <h2 class="step-4_title title--green">
          Dit heeft u misschien ook nodig
        </h2>
        <div
          v-for="item in additionalAccessoriesArray"
          :key="item.articleNumber"
        >
          <HorizontalCardCheckbox
            :cardImage="item.imageURL"
            cardImageAlt="image"
            :title="item.title"
            :subtitle="item.description"
            :articleNumber="item.articleNumber"
            :suppliercode="item.supplierCode"
            :productQuantity="item.numberOfUnits"
            :deliveryTimeDelay="item.deliveryTime"
            checkerName="accessoriesCard"
            :cardOptions="item.articleNumber"
            :price="item.price"
            :brutoPrice="item.brutoPrice"
            @checkedcard="additionalAccessoriesChecked"
          />
        </div>
      </div>
      <h2
        class="step-4_title title--green"
        v-if="calculateTotalPrice() != 'NoApiCall'"
      >
        Totaalprijs
      </h2>
      <span class="step-4_price" v-if="calculateTotalPrice() != 'NoApiCall'"
        >€ {{ calculateTotalPrice() }}</span
      >
      <span
        class="step-4_price-description"
        v-if="calculateTotalPrice() != 'NoApiCall'"
        >Netto ex. BTW en toeslagen</span
      >
      <Button
        @clickedBtn="submit"
        buttonText="Naar controle"
        buttonVariation="button--primary"
        :disableButton="false"
        class="button-selectie"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  GET_VEHICLE_BRAND,
  GET_CHARGER_POSITION,
  GET_CHARGING_CABLE,
  GET_VEHICLE_CHARGING_UNITS,
  GET_COSTS,
  GET_VEHICLE_MODEL,
  GET_AMPERES,
  GET_PAHSE,
  GET_LOADBALANCING,
  PRODUCT_CARD_LIST,
  GET_PRODUCT_CARD_LIST,
  GET_USER_TOKEN_GETTER,
  PRODUCT_ACCESSORIES,
  GET_CABLES_LIST,
  GET_ADDITIONAL_ACCESSORIES_LIST,
  SET_FOURTH_STEP_CHOICES,
  GET_CABLE_TYPE,
  SORT_PRODUCTS,
} from "@/store/storeconstants";

import Button from "@/components/atoms/Button/Button.vue";
import SelectionResults from "@/components/molecules/SelectionResults/SelectionResults.vue";
import ChargingUnitVerticalCard from "@/components/molecules/ChargingUnitVerticalCard/ChargingUnitVerticalCard.vue";
import HorizontalCardProduct from "@/components/molecules/HorizontalCard/HorizontalCardProduct.vue";
import HorizontalCard from "@/components/molecules/HorizontalCard/HorizontalCard.vue";
import HorizontalCardCheckbox from "@/components/molecules/HorizontalCard/HorizontalCardCheckbox.vue";
import PlainButton from "@/components/atoms/PlainButton/PlainButton.vue";
import Loader from "@/components/atoms/Loader/Loader.vue";
import DropdownList from "@/components/atoms/DropdownList/DropdownList.vue";

import Arrows from "@/assets/icons/arrow-sprite.svg";
import FilterIcon from "@/assets/icons/filter-icon.svg";
import MessageCard from "@/components/molecules/MessageCard/MessageCard.vue";

export default {
  emits: ["clickednext", "submitstep4"],
  components: {
    Button,
    SelectionResults,
    ChargingUnitVerticalCard,
    HorizontalCardProduct,
    HorizontalCard,
    HorizontalCardCheckbox,
    PlainButton,
    Loader,
    DropdownList,
    MessageCard
  },
  data: () => ({
    filterIcon: FilterIcon,
    showNextPrevButtons: false,
    nextArrow: Arrows + "#next-arrow",
    prevArrow: Arrows + "#prev-arrow",
    selectedProduct: false,
    selectedCardsArray: [],
    selectedCard: {},
    selectedCablesArray: [],
    selectedCardsForComparison: false,
    finalProductSelection: {},
    cardsTitle: "Maak uw keuze voor een laadunit.",
    disableVerticalCardButton: true,
    additionAccessoriesSelectedCard: {},
    additionAccessoriesArray: [],
    cableSelection: {},
    totalPrice: "",
    totalPriceCount: "",
    isLoading: true,
    selectedSorting: "",
  }),
  computed: {
    ...mapGetters("app", {
      selectedMark: GET_VEHICLE_BRAND,
      selectedSeries: GET_VEHICLE_MODEL,
      selectedUnitsNumber: GET_VEHICLE_CHARGING_UNITS,
      selectedCostOption: GET_COSTS,
      selectedChargerPosition: GET_CHARGER_POSITION,
      selectedChargingCable: GET_CHARGING_CABLE,
      selectedPhases: GET_PAHSE,
      selectedAmpere: GET_AMPERES,
      selectedLoadbalancing: GET_LOADBALANCING,
      productCardsArray: GET_PRODUCT_CARD_LIST,
      cablesArray: GET_CABLES_LIST,
      additionalAccessoriesArray: GET_ADDITIONAL_ACCESSORIES_LIST,
      chargePlug: GET_CABLE_TYPE,
    }),
    ...mapGetters("auth", {
      access_token: GET_USER_TOKEN_GETTER,
    }),
  },
  methods: {
    ...mapMutations("app", {
      setFourthStepChoices: SET_FOURTH_STEP_CHOICES,
      sortCards: SORT_PRODUCTS,
    }),
    ...mapActions("app", {
      productCardList: PRODUCT_CARD_LIST,
      productAccessories: PRODUCT_ACCESSORIES,
    }),
    scrollLeft() {
      let content = document.querySelector(".selection-results_cards-wrapper");
      const screenWidth = window.innerWidth;

      if (screenWidth <= 511) {
        content.scrollLeft -= 270;
      } else {
        content.scrollLeft -= 334;
      }
    },
    scrollRight() {
      let content = document.querySelector(".selection-results_cards-wrapper");
      const screenWidth = window.innerWidth;

      if (screenWidth <= 511) {
        content.scrollLeft += 270;
      } else {
        content.scrollLeft += 334;
      }
    },
    isOverflowing() {
      const productCardsWrapper = this.$refs.productCardsWrapper;
      const step4Wrapper = this.$refs.step4Wrapper;

      if (
        productCardsWrapper &&
        productCardsWrapper.scrollWidth > step4Wrapper.offsetWidth
      ) {
        this.showNextPrevButtons = true;
      } else {
        this.showNextPrevButtons = false;
      }
    },
    selectedForComparison(
      checked,
      item
    ) {
      this.selectedCard = {
        typeConnectionBackend: item.typeConnectionBackend,
        fuseRatingConnection: item.fuseRatingConnection,
        accessControl: item.accessControl,
        loadBalancing: item.loadBalancing,
        plug: item.plug,
        dynamicLoadManagement: item.dynamicLoadManagement,
        model: item.model,
        includingBackoffice: item.includingBackoffice,
        articleNumber: item.articleNumber,
        shortDescription: item.subtitle,
        deliveryTime: item.deliveryTimeDelay,
        numberOfUnits: item.numberOfUnits,
        supplierCode: item.supplierCode,
        consumerFriendlyTitle: item.title,
        price: item.price,
        imageURL: item.imageURL,
        color: item.color,
        manageType: item.manageType,
        nominalConnectionValue : item.nominalConnectionValue,
        includingSimBackoffice : item.includingSimBackoffice,
        withEnergyMeter : item.withEnergyMeter,
        communicationInterface : item.communicationInterface,
        display: item.display,
        solarPanelCompatible: item.solarPanelCompatible,
        cableLength: item.cableLength,
        brutoPrice: item.brutoPrice
      };

      const found = this.selectedCardsArray.some(
        (el) => el.articleNumber === this.selectedCard.articleNumber
      );

      if (document.getElementById(item.articleNumber).checked === true) {
        // Add selected card in array
        this.selectedCardsArray.push(this.selectedCard);
      } else if (document.getElementById(item.articleNumber).checked === false) {
        // remove card from the array if card is unselected
        for (let i = 0; i < this.selectedCardsArray.length; i++) {
          if (
            this.selectedCardsArray[i].articleNumber ===
            this.selectedCard.articleNumber
          ) {
            this.selectedCardsArray.splice(i, 1);
          }
        }
      }
    },
    checkNumberOfProducts() {
      this.isLoading = false;
      this.cardsTitle = `Uw criteria hebben geen resultaat opgeleverd, pas uw criteria aan of neem <a href="https://www.c.technischeunie.nl/vestigingen.html" target="_blank" class="title--green">contact</a> op met uw Verkoopkantoor`;
      if (this.productCardsArray) {
        if(this.productCardsArray.length === 0){
          this.selectedCardsArray = this.productCardsArray;
          this.selectedCardsForComparison = true;
        } else {
          this.cardsTitle = `Er zijn ${this.productCardsArray.length} laadunits die aan uw eisen voldoen. Maak hier uw keuze.`;
        }
      }
    },
    compareProducts() {
      if (
        this.selectedCardsArray.length > 0 &&
        this.selectedCardsArray.length <= 4
      ) {
        this.selectedCardsForComparison = true;
        this.cardsTitle = "Maak uw keuze voor een laadunit.";
        setTimeout(
          function () {
            this.isOverflowing();
          }.bind(this),
          50
        );
      } else {
        this.selectedCardsForComparison = false;
        this.cardsTitle = `Er zijn ${this.productCardsArray.length} laadunits die aan uw eisen voldoen. Maak hier uw keuze.`;
      }
    },
    confirmSelection() {
      this.selectedProduct = true;
      window.scrollTo({ top: 0, behavior: "smooth" });

      this.productAccessories({
        articleNumber: this.finalProductSelection.articleNumber,
        token: this.access_token,
      });
    },
    directSelectedProduct(item) {
      this.finalProductSelection = {
        cardOptions: item.cardOptions,
        checkerName: item.checkerName,
        image: item.cardImage,
        cardImageAlt: item.cardImageAlt,
        title: item.title,
        subtitle: item.subtitle,
        articleNumber: item.articleNumber,
        suppliercode: item.suppliercode,
        productQuantity: item.productQuantity,
        deliveryTimeDelay: item.deliveryTimeDelay,
        price: item.price,
        typeConnectionBackend: item.typeConnectionBackend,
        fuseRatingConnection: item.fuseRatingConnection,
        accessControl: item.accessControl,
        plug: item.plug,
        loadBalancing: item.loadBalancing,
        dynamicLoadManagement: item.dynamicLoadManagement,
        model: item.model,
        includingBackoffice: item.includingBackoffice,
        manageType: item.manageType,
        nominalConnectionValue : item.nominalConnectionValue,
        includingSimBackoffice : item.includingSimBackoffice,
        withEnergyMeter : item.withEnergyMeter,
        communicationInterface : item.communicationInterface,
        brutoPrice : item.brutoPrice,
      };
      this.confirmSelection();
    },
    finalProductSelected(
      checked,
      cardOptions,
      checkerName,
      cardImage,
      cardImageAlt,
      title,
      subtitle,
      articleNumber,
      suppliercode,
      productQuantity,
      deliveryTimeDelay,
      price,
      brutoPrice
    ) {
      if (checked) {
        this.enableVerticalCardButton();
      }

      this.finalProductSelection = {
        checked: checked,
        cardOptions: cardOptions,
        checkerName: checkerName,
        image: cardImage,
        cardImageAlt: cardImageAlt,
        title: title,
        subtitle: subtitle,
        articleNumber: articleNumber,
        suppliercode: suppliercode,
        productQuantity: productQuantity,
        deliveryTimeDelay: deliveryTimeDelay,
        price: price,
        brutoPrice: brutoPrice,
      };
    },
    backToAllUnits() {
      this.selectedCardsForComparison = false;
      this.selectedCardsArray = [];
      this.selectedProduct = false;
      this.disableVerticalCardButton = true;
      this.finalProductSelection = {};
      this.checkNumberOfProducts();
    },
    addBorder(item) {
      // Check if card is selected return class to show green border
      if (this.finalProductSelection.cardOptions == item) {
        return "charging-unit-vertical-card--checked";
      }
    },
    enableVerticalCardButton() {
      this.disableVerticalCardButton = false;
    },
    additionalAccessoriesChecked(
      checked,
      cardOptions,
      checkerName,
      cardImage,
      cardImageAlt,
      title,
      subtitle,
      articleNumber,
      suppliercode,
      productQuantity,
      deliveryTimeDelay,
      cardTag,
      price,
      brutoPrice
    ) {
      this.additionAccessoriesSelected = {
        checked,
        cardOptions,
        checkerName,
        cardImage,
        cardImageAlt,
        title,
        subtitle,
        articleNumber,
        suppliercode,
        productQuantity,
        deliveryTimeDelay,
        cardTag,
        price,
        brutoPrice,
      };
      const found = this.additionAccessoriesArray.some(
        (el) =>
          el.articleNumber === this.additionAccessoriesSelected.articleNumber
      );
      if (!found && checked.length > 0) {
        // Add selected card in array
        this.additionAccessoriesArray.push(this.additionAccessoriesSelected);
      } else if (found && checked.length <= 0) {
        // remove card from the array if card is unselected
        for (let i = 0; i < this.additionAccessoriesArray.length; i++) {
          if (
            this.additionAccessoriesArray[i].articleNumber ===
            this.additionAccessoriesSelected.articleNumber
          ) {
            this.additionAccessoriesArray.splice(i, 1);
          }
        }
      }
    },
    selectedCable(
      checked,
      item
    ) {
      this.cableSelection = {
        checked: item.checked,
        cardOptions: item.cardOptions,
        checkerName: item.checkerName,
        image: item.imageURL,
        cardImageAlt: item.cardImageAlt,
        title: item.title,
        subtitle: item.subtitle,
        articleNumber: item.articleNumber,
        suppliercode: item.suppliercode,
        productQuantity: item.productQuantity,
        deliveryTimeDelay: item.deliveryTimeDelay,
        price: item.price,
        cableLength: item.cableLength,
        brutoPrice: item.brutoPrice,
      };

      const found = this.selectedCablesArray.some(
        (el) => el.articleNumber === this.cableSelection.articleNumber
      );

      if (document.getElementById(this.cableSelection.articleNumber).checked === true) {
        // Add selected card in array
        this.selectedCablesArray.push(this.cableSelection);
      } else if (document.getElementById(this.cableSelection.articleNumber).checked === false) {
        // remove card from the array if card is unselected
        for (let i = 0; i < this.selectedCablesArray.length; i++) {
          if (
            this.selectedCablesArray[i].articleNumber ===
            this.cableSelection.articleNumber
          ) {
            this.selectedCablesArray.splice(i, 1);
          }
        }
      }

    },
    calculateTotalPrice() {
      let productPrice = Number(this.finalProductSelection.price);
      let cabelPrice = 0;
      this.selectedCablesArray.forEach((item) => {
        cabelPrice += parseFloat(item.price);
      });
      let additionalAccessoriesPrice = 0;
      this.additionAccessoriesArray.forEach((item) => {
        additionalAccessoriesPrice += parseFloat(item.price);
      });
      this.totalPrice = productPrice + cabelPrice + additionalAccessoriesPrice;
      if (this.totalPrice >= 0) {
        return this.totalPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency:"EUR",
            });
      } else {
        return "NoApiCall";
      }
    },
    uncheckCheckbox() {
      let checkboxes = document.querySelectorAll(".checkbox_input");
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          checkbox.click();
        }
      });
    },
    sortButton(option) {
      let sortedArray = this.productCardsArray;
      if (option === "Prijs oplopend") {
        sortedArray.sort((a, b) =>
          parseFloat(a.price) > parseFloat(b.price) ? 1 : -1
        );
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      } else if (option === "Prijs aflopend") {
        sortedArray.sort((a, b) =>
          parseFloat(a.price) > parseFloat(b.price) ? -1 : 1
        );
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      } else if (option === "Voorraad oplopend") {
        sortedArray.sort((a, b) =>
          parseFloat(a.numberOfUnits) > parseFloat(b.numberOfUnits) ? 1 : -1
        );
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      } else if (option === "Voorraad aflopend") {
        sortedArray.sort((a, b) =>
          parseFloat(a.numberOfUnits) > parseFloat(b.numberOfUnits) ? -1 : 1
        );
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      } else if (option === "A-Z") {
        sortedArray.sort((a, b) =>
          a.consumerFriendlyTitle.localeCompare(b.consumerFriendlyTitle)
        );
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      } else if (option === "Z-A") {
        sortedArray.sort((a, b) =>
          a.consumerFriendlyTitle.localeCompare(b.consumerFriendlyTitle)
        );
        sortedArray.reverse();
        this.selectedCardsArray = [];
        this.uncheckCheckbox();
      }
      this.sortCards(sortedArray);
    },
    submit() {
      this.$emit("submitstep4");
      this.setFourthStepChoices({
        selectedProduct: this.finalProductSelection,
        selectedCables: this.selectedCablesArray,
        selectedAccessories: this.additionAccessoriesArray,
      });
    },
  },
  mounted() {
    let typeOne = ""
    let typeTwo = ""
    if(this.chargePlug == "Type 2") {
        typeOne = false
        typeTwo = true
    } else if (this.chargePlug == "Type 1") {
        typeOne = true
        typeTwo = false
    } else {
        typeOne = true
        typeTwo = true
    }
    this.productCardList({
      automaticInvoicing: this.selectedCostOption === "Anders dan GEEN" ? true : false,
      wallMount: this.selectedChargerPosition === "Muur" ? true : false,
      fixedCable: this.selectedChargingCable === "Contactdoos type 2" ? false : true,
      monoPhase: this.selectedPhases === "1 fase" ? true : false,
      token: this.access_token,
      cableTypeOne: typeOne,
      cableTypeTwo: typeTwo,
      loadBalancing: this.selectedLoadbalancing === "false" ? true : false,
      chargingUnits: this.selectedUnitsNumber,
    });
    setTimeout(
      function () {
        this.isOverflowing();
      }.bind(this),
      50
    );

    window.addEventListener(
      "resize",
      function (event) {
        this.isOverflowing();
      }.bind(this),
      true
    );
  },
  watch: {
    productCardsArray() {
      this.checkNumberOfProducts();
      this.sortButton("Voorraad aflopend");
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/components/organisms/Step4/step4.scss";
</style>
