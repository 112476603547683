<template>
  <h2 class="title--green">Aansluiting</h2>

  <RadioButtonCardGroups
    title="Heeft de (toekomstige) meterkast een 1- of 3-fase aansluiting?"
    description="Een 3-fase aansluiting heeft meer mogelijkheden dan een 1-fase aansluiting."
    :optionsArray="phaseConnection"
    chargingSpeed
    :chargingTimeInHours="chargingHours"
    :chargingTimeIcon="chargingHoursIcon"
    @checkedRadioOption="checkedPhases"
    @clickedlink="toggleModal2"
    @clickedlink2="toggleModal2"
    @clickedlink3="toggleModal3"
  />
  <DropdownList
    v-if="showAmperesOptions"
    label="Aantal ampère van de hoofdaansluiting"
    id="amperedropdown"
    value="-"
    :optionsList="ampereList"
    ddClass="dropdown-list_wrapper--small"
    @selectedOption="getChargingHours"
  />
  <RadioButtonCardGroups
    title="Statische of dynamische loadbalancing?"
    description="Dynamische load balancing zorgt ervoor dat de meterkast minder snel overbelast raakt. "
    :optionsArray="loadbalancing"
    @checkedRadioOption="checkedLoadbalancer"
    :warningMessage="loadbalancerWarningMessage"
    @clickedlink="toggleModal1"
  />
  <Button
    @clickedBtn="submit"
    buttonText="Naar selectie"
    buttonVariation="button--primary"
    :disableButton="disabledButton"
    class="button-aansluiting"
  />
  <!-- Modal 1 -->
  <Modal
    :modalPaddingNone="true"
    :show="showModal1"
    @close="closeModal1"
    @clickbackgdrop="closeModal1"
    :closeButton="showModalCloseButton1"
  >
    <MoreInformations title="Statische of dynamische load balancing?">
      <p>
        Elke laadunit beschikt over statische load balancing, dit betekent dat de laadunit is ingesteld op een maximaal vermogen. Dynamische load balancing is een techniek waarmee er optimaal en variabel gebruik wordt gemaakt van het vermogen van de hoofdaansluiting. 
      </p>
      <p>
        Bij een aantal laadunits zijn extra toebehoren nodig voor dynamische load balancing, zoals een kWh-meter of meetspoel. Deze toebehoren zijn ook afhankelijk van de in de meterkast aanwezige componenten. Mocht u vragen hebben, neem dan contact op met uw verkoopkantoor.
      </p>
    </MoreInformations>
  </Modal>
  <!-- Modal 2 -->
  <Modal
    :modalPaddingNone="true"
    :show="showModal2"
    @close="closeModal2"
    @clickbackgdrop="closeModal2"
    :closeButton="showModalCloseButton2"
  >
    <MoreInformations title="1 of 3 fase aansluiting">
      <p>
       Afhankelijk van uw hoofdaansluiting, kan er een 1- of 3-fase laadunit worden geplaatst.
      </p>
      <p>
        <strong>Heeft u een 1-fase aansluiting?</strong> Informeer dan of het mogelijk is om een 3-fase aansluiting te krijgen (Let er wel op dat de meterkast aangepast dient te worden).
      </p>
      <p>
        <strong>Heeft u een 3-fase aansluiting?</strong> Afhankelijk van het beschikbare ampère, kunt u in de meeste gevallen 1 of meerdere 11 kW laadpalen plaatsen met dynamisch load balancing.
      </p>
      <p>
        Dynamisch load balancing is een techniek waarmee u voorkomt dat de hoofdaansluiting overbelast wordt. Hiervoor heeft u een slimme meter met p1 poort of een extra kWh-meter nodig, die het verbruik van de installatie constant meet en doorgeeft aan de laadpaal.
      </p>
    </MoreInformations>
  </Modal>
  <!-- Modal 3 -->
  <Modal
    :modalPaddingNone="true"
    :show="showModal3"
    @close="closeModal3"
    @clickbackgdrop="closeModal3"
    :closeButton="showModalCloseButton3"
  >
    <MoreInformations title="Laadsnelheid">
      <p>
      De laadsnelheid wordt afgelezen uit de EV-database. Dit op basis van de aanwezige hoofdaansluiting (met selectieve afzekerwaarde) en de gekozen auto. Voor meer informatie kijk op: <a href="https://ev-database.org/" target="_blank" >https://ev-database.org/</a>
      </p>
    </MoreInformations>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CHARGING_TIME,
  GET_CHARGING_TIME,
  GET_VEHICLE_BRAND,
  GET_VEHICLE_MODEL,
  GET_VEHICLE_VERSION,
  SET_THIRD_STEP_CHOICES,
  GET_VEHICLE_CHARGING_UNITS,
} from "@/store/storeconstants";

import RadioButtonCardGroups from "@/components/organisms/RadioButtonCardGroups/RadioButtonCardGroups.vue";
import Button from "@/components/atoms/Button/Button.vue";
import DropdownList from "@/components/atoms/DropdownList/DropdownList.vue";
import Modal from "@/components/containers/Modal/Modal.vue";
import MoreInformations from "@/components/molecules/MoreInformations/MoreInformations.vue";

import Monophase from "@/assets/icons/monophase.svg";
import Threephase from "@/assets/icons/threephase.svg";
import StaticLoadbalancer from "@/assets/icons/static-loadbalancer.svg";
import DynamicLoadbalancer from "@/assets/icons/dynamic-loadbalancer.svg";

export default {
  emits: ["submitstep3"],
  components: {
    RadioButtonCardGroups,
    Button,
    DropdownList,
    Modal,
    MoreInformations,
  },
  data: () => ({
    step: 1,
    phaseConnection: [
      {
        image: Monophase,
        imageAlt: "img",
        option: "1 fase",
        radioName: "phaseConnection",
        radioValue: "1 fase"
      },
      {
        image: Threephase,
        imageAlt: "img",
        option: "3 fase",
        radioName: "phaseConnection",
      },
    ],
    loadbalancing: [
      {
        image: StaticLoadbalancer,
        imageAlt: "img",
        option: "Statisch",
        radioName: "loadbalancing",
        radioValue: "true"
      },
      {
        image: DynamicLoadbalancer,
        imageAlt: "img",
        option: "Dynamisch",
        radioName: "loadbalancing",
        radioValue: "false"
      },
    ],
    phaseChecked: "",
    ampereList: [],
    ampereDDdisabled: "disabled",
    chargingHours: "",
    chargingHoursIcon: "",
    loadbalancerWarningMessage: "",
    selectedPhases: "",
    selectedAmpere: "",
    selectedLoadbalancing: "",
    disabledButton: true,
    showModal1: false,
    showModalCloseButton1: true,
    showModal2: false,
    showModalCloseButton2: true,
    showModal3: false,
    showModalCloseButton3: true,
    chargingTimeData: [],
    chargePlug: "",
    showAmperesOptions: true,
  }),
  computed: {
    ...mapGetters("app", {
      response: GET_CHARGING_TIME,
      vehicleModel: GET_VEHICLE_MODEL,
      vehicleVersion: GET_VEHICLE_VERSION,
      vehicleBrand: GET_VEHICLE_BRAND,
      vehicleChargingUnits: GET_VEHICLE_CHARGING_UNITS,
    }),
  },
  methods: {
    ...mapMutations("app", {
      setThirdStepChoices: SET_THIRD_STEP_CHOICES,
    }),
    ...mapActions("app", {
      chargingTime: CHARGING_TIME,
    }),
    checkedPhases(checked) {
      this.phaseChecked = checked;

      if (checked == "1 fase") {
        this.ampereList = ["10", "25", "30", "35", "40"];
      } else if (checked == "3 fase") {
        this.ampereList = ["25", "35", "50", "63", "80"];
      } else {
        this.ampereList = [];
      }

      this.selectedPhases = checked;
      this.selectedAmpere = "";
      this.chargingHours = "";
      this.enableButton();
    },
    checkedLoadbalancer(checked) {
      if (checked == "true") {
        this.loadbalancerWarningMessage =
          "Let op! Bij statische loadbalancing kan uw hoofdaansluiting overbelast raken.";
      } else if (checked == "false") {
        this.loadbalancerWarningMessage = "";
      } else {
        this.loadbalancerWarningMessage = "";
      }

      this.selectedLoadbalancing = checked;
      this.enableButton();
    },
    enableButton() {
      if(true){
        if (
          this.selectedPhases !== "" &&
          this.selectedAmpere !== "" &&
          this.selectedLoadbalancing !== ""
        ) {
          this.disabledButton = false;
        } else if (
          this.showAmperesOptions === false &&
          this.selectedPhases !== "" &&
          this.selectedLoadbalancing !== ""
        ) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
        }
      } else{

      }
    },
    submit() {
      this.$emit("submitstep3");
      this.setThirdStepChoices({
        loadbalancing: this.selectedLoadbalancing,
        phase: this.selectedPhases,
        amperes: this.selectedAmpere,
        chargePlug: this.chargePlug,
      });
    },
    toggleModal1() {
      this.showModal1 = !this.showModal1;

      if (this.showModal1 == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
    closeModal1() {
      this.showModal1 = false;
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    },
    toggleModal2() {
      this.showModal2 = !this.showModal2;

      if (this.showModal2 == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
    closeModal2() {
      this.showModal2 = false;
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    },
     toggleModal3() {
      this.showModal3 = !this.showModal3;

      if (this.showModal3 == true) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      } else {
        document.getElementsByTagName("body")[0].style = "overflow: auto;";
      }
    },
    closeModal3() {
      this.showModal3 = false;
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    },
    getChargingHours(ampere) {
      this.enableButton();
      if(this.response.data && this.response.data.brand !== "Onbekend" ){
      this.chargingTimeData = this.response.data.chargeTimeDto;
      this.chargePlug = this.response.data.chargePlug;

      for (let i in this.chargingTimeData) {
        let hours = (this.chargingTimeData[i] / 60).toFixed(2);

        if (ampere == i.slice(-2)) {
          if (this.phaseChecked == "1 fase" && i.slice(0, 2) == "p1") {
            // check hours for clock icon
            if (hours <= 4) {
              this.chargingHoursIcon = "clock-15";
            } else if (hours > 4 && hours <= 6) {
              this.chargingHoursIcon = "clock-30";
            } else if (hours > 6) {
              this.chargingHoursIcon = "clock-40";
            }
            // set hours
            this.chargingHours = hours.toString();
          } else if (this.phaseChecked == "3 fase" && i.slice(0, 2) == "p3") {
            // check hours for clock icon
            if (hours <= 4) {
              this.chargingHoursIcon = "clock-15";
            } else if (hours > 4 && hours <= 6) {
              this.chargingHoursIcon = "clock-30";
            } else if (hours > 6) {
              this.chargingHoursIcon = "clock-40";
            }
            // set hours
            this.chargingHours = hours.toString();
          }
        }
      }      
      }
      this.selectedAmpere = ampere;  
    },
    
  },
  mounted() {
    if (
      this.vehicleModel !== "alle series" &&
      this.vehicleBrand !== "alle merken"
    ) {
      this.chargingTime({
        model: this.vehicleModel,
        brand: this.vehicleBrand,
        version: this.vehicleVersion,
      });
    } else if (
      this.vehicleModel === "alle series" &&
      this.vehicleBrand === "alle merken"
    ) {
      this.chargePlug = "default";
      this.showAmperesOptions = true;
    }
  },
};
</script>

<style>
</style>