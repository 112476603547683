import {
  GET_AUTOLOGOUT,
  GET_USERNAME,
  GET_USER_TOKEN_GETTER,
  IS_USER_AUTHENTICATED_GETTER,
  GET_CUSTOMERNUMBER
} from "@/store/storeconstants";

export default {
    [GET_USER_TOKEN_GETTER]: (state) => {
        return state.access_token
    },

    [IS_USER_AUTHENTICATED_GETTER]: (state) => {
        return !!state.access_token
    },

    [GET_USERNAME]: (state) => {
        return state.username
    },

    [GET_CUSTOMERNUMBER]: (state) => {
      return state.customer_number
    },

    [GET_AUTOLOGOUT]: (state) => {
        return state.autoLogout
    },
};
